import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueCookies from 'vue-cookies'

Vue.use(VueI18n);
Vue.use(VueCookies);

const messages = {
    'en': {
        // Header.vue
        login: 'Login',
        register: 'Register',
        logout: 'Logout',
        addCommentButton: 'Add a comment', // Shared on PageLanding.vue
        // PageAddComment.vue
        addCommentTitle: 'Add a comment to the map',
        addCommentPreface: 'We want to hear about your active travel experiences on our roads and footways. Using the map you can pinpoint part of your journey and make a comment about your experiences at that point.',
        addCommentAnonymous: 'All submissions remain anonymous.',
        addCommentResponse: 'Response required',
        addCommentFindLocation: 'Find a location',
        addCommentFindLocationDesc: 'Use the search box to find a location',
        addCommentCommentTitle: 'Comment Title',
        addCommentCommentTitleDesc: 'Enter a title for your comment here (50 characters maximum)',
        addCommentCategories: 'Categories', // Category pill titles need to be added to DB
        addCommentCategoriesDesc: 'Select the main categories relevant to your comment',
        addCommentSentiment: 'How do you feel?',
        addCommentSentimentDesc: 'Use the slider to indicate how positive or negative you feel about this issue',
        addCommentActivities: 'Activities', // Need to make the options update based on language, titles are in DB
        addCommentActivitiesDesc: 'Do you feel like this when you are...', // Subject to change
        addCommentAction: 'Suggested Action', // Needs welsh versions added to the DB
        addCommentActionDesc: 'What action needs to be taken here? Select the most relevant from the dropdown menu. More detail can be given in the comment box below',
        addImageAction: 'Add an image', // Needs welsh versions added to the DB
        addImageActionDesc: 'If you have relevant images to illustrate your comment, please upload them here (maximum two images, maximum size 10MB each)',
        addCommentComment: 'Comment',
        addCommentCommentDesc: 'Use the field below to tell us more about this area (600 characters maximum)',
        addCommentSubmit: 'Submit comment',
        addCommentCovid: 'Let us know if this comment relates to Covid-19',
        addCommentLoading: "Thanks for your comment, we're taking you to it now...",
        // PageLanding.vue - Contents here will be subject to the copy provided by the client, placeholders of the current layout are used in this section
        welcomeTitle: 'Welcome to Carmarthenshire’s Active Travel Network Map',
        pageLandingPrefaceOne: 'This map is for everyone who wants to comment on issues that affect their Active Travel journeys.  Active travel simply means making journeys in physically active ways - like walking, wheeling (using a wheelchair or mobility aid), cycling, or scootering.  Carmarthenshire County Council continue to welcome suggestions on additional routes or enhancements that would improve your experience of the network and travelling around the county for day-to-day journeys.',
        pageLandingPrefaceTwo: 'You can provide your feedback by adding a comment to the map. There is now also the option to upload photos as evidence to your comment.',
        pageLandingPrefaceThree: 'These comments will be monitored and recorded as an evidence base to be used in any future development or construction funding bids.',
        pageLandingPrefaceFour: 'The base map has been updated with an overlay of the approved Active Travel Network Map (ATNM).  The map contains:',
        pageLandingPrefaceBulletOne: '‘Existing Routes’ that have been audited and are in line with Active Travel design guidelines and;',
        pageLandingPrefaceBulletTwo: 'Potential ‘Future Routes’ which have been identified following public and stakeholder consultation, along with routes that have not been audited yet.',
        pageLandingPrefaceExtra: 'Zoom into an area of interest to see the Existing Route Network and identified Future Routes in more detail. You can also clear these routes from the map by unchecking them in the map key.',
        pageLandingPrefaceExtraOne: 'Zoom into an area of interest to see the approved',
        pageLandingPrefaceExtraTwo: "Existing Route Network and identified",
        pageLandingPrefaceExtraThree: "Future Routes in more detail. You can also clear these routes from the map by unchecking them in the map key.",
        pageLandingCollapseOne: "This map is for everyone who wants to make journeys on foot or by bike across our region, for any purpose, including those who don’t walk or cycle often and people who use mobility aids. We would like your comments on the infrastructure we already have and what you would like to see in the future. Comments will then be used, alongside those we have already gathered, to help plan Carmarthenshire’s Active Travel Network Map.",
        pageLandingCollapseTwo: "Following the passing of the Active Travel (Wales) Act in 2013 all Local Authorities in Wales are required to produce maps of Active Travel networks. These maps will help to identify opportunities to expand and improve the network and facilities, whilst also highlighting what has been done well. Routes are to be coherent, direct, safe, comfortable and attractive.",
        pageLandingCollapseThree: "Built Up Areas with populations exceeding 2000 people and predominantly residential land use have been identified in the Act and are shown on our online map. While Active Travel Funding has historically been limited to these areas, we are seeking countywide feedback to create a cohesive network across Carmarthenshire and linking with neighbouring authorities.",
        pageLandingCollapseFour: "Additional information on the Act can be found at: ",
        pageLandingLearnMore: 'Learn more about the project',
        // PageMap.vue
        pageMapRegisterPopupHeader: 'Thank you for registering an account with us.',
        pageMapRegisterPopupAddedComment: 'Your comment has been added to the map. You can now add more comments.',
        pageMapRegisterPopupAddComment: 'You can now add comments to the map.',
        pageMapCookiePopupHeader: 'This site uses cookies: ',
        pageMapCookiePopupLink: 'Find out more',
        pageMapCookiePopupButton: 'Okay, Thanks',
        pageMapLegendRowOne: 'Carmarthenshire boundary',
        pageMapLegendRowTwo: 'Built up areas',
        // PageViewComment.vue - Need to apply activities and actions changes, refer to categories language update
        pageViewCommentModerated: 'This comment is not yet verified by our moderators',
        pageViewCommentShare: 'Share this',
        pageViewCommentAction: 'Action',
        pageViewCommentRelate: 'This comment relates to',
        pageViewCommentWhen: 'I felt like this when',
        pageViewCommentAdded: 'This comment was added in',
        pageViewCommentImages: 'Related Image(s)',
        pageViewImageModerated: 'The image for this comment has not yet been verified',
        pageViewEnlgish: 'English',
        pageViewWelsh: 'Welsh',
        pageViewPhasePrefix: 'This comment was submitted during phase ', // I should be able to use {0} to add the variable in, for some reason it's not working though
        pageViewPhaseSuffix: ' of the consultation',
        // TheMap.vue
        mapKey: 'Map Key',
        mapBuiltOn: 'Built on',
        mapImprove: 'Improve this map',
        mapERN: 'Existing Route Network',
        mapASR: 'Future Routes',
        mapComment: 'Comments',
        mapSentVN: 'Very Negative',
        mapSentQN: 'Quite Negative',
        mapSentN: 'Neutral',
        mapSentQP: 'Quite Positive',
        mapSentVP: 'Very Positive',
        mapTooltipMapKey: 'Open the map key',
        mapTooltipTravel: 'Travel to an area',
        mapTooltipMapStyle: 'Change the map style',
        mapTooltipResetView: 'Reset the map view',
        mapGoTo: 'Go to',
        mapCycling: 'Cycling',
        mapSharedUse: 'Shared Use',
        mapWalking: 'Walking',
        mapUseMyLocation: "Use my location",
        mapInvalidLocation: "Your location appears to be outside of Carmarthenshire. Please use the search box instead.",
        // Dropzone
        dropzoneDefaultMessage: "Drop files here to upload",
        dropzoneFallbackMessage: "Your browser does not support drag and drop file uploads.",
        dropzoneFallbackText: "Please use the fallback form below to upload your files like in the olden days.",
        dropzoneFileTooBig: "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.",
        dropzoneInvalidFileType: "You can't upload files of this type.",
        dropzoneResponseError: "Server responded with {{statusCode}} code.",
        dropzoneCancelUpload: "Cancel upload",
        dropzoneUploadCanceled: "Upload canceled.",
        dropzoneCancelUploadConfirmation: "Are you sure you want to cancel this upload?",
        dropzoneRemoveFile: "Remove file",
        dropzoneMaxFilesExceeded: "You can not upload any more files.",
    },
    'cy': {
        // Header.vue
        login: 'Mewngofnodi',
        register: 'Cofrestru',
        logout: 'Allgofnodi',
        addCommentButton: 'Ychwanegu sylw', // Shared on PageLanding.vue
        // PageAddComment.vue
        addCommentTitle: 'Ychwanegwch sylw at y map',
        addCommentPreface: "Hoffwn glywed am eich profiadau Teithio Llesol ar ein ffyrdd a'n llwybrau. Gan ddefnyddio'r map, gallwch nodi rhan o'ch siwrnai a gwneud sylw.",
        addCommentAnonymous: "Cedwir yr holl sylwadau'n ddienw",
        addCommentResponse: 'Angen ymateb',
        addCommentFindLocation: 'Dod o hyd i leoliad',
        addCommentFindLocationDesc: 'Defnyddiwch y blwch chwilio i ddod o hyd i leoliad',
        addCommentCommentTitle: 'Teitl y sylw',
        addCommentCommentTitleDesc: 'Nodwch deitl ar gyfer eich sylw yma (dim mwy na 50 nod)',
        addCommentCategories: 'Categorïau', // Category pill titles need to be added to DB
        addCommentCategoriesDesc: "Dewiswch y prif gategorïau sy'n berthnasol i'ch sylw",
        addCommentSentiment: "Sut ydych chi'n teimlo?",
        addCommentSentimentDesc: 'Defnyddiwch y llinell i nodi pa mor gadarnhaol neu negyddol rydych yn teimlo am y mater hwn',
        addCommentActivities: 'Gweithgareddau', // Need to make the options update based on language, titles are in DB
        addCommentActivitiesDesc: 'A ydych yn teimlo fel hyn pan rydych yn... ', // Subject to change
        addCommentAction: 'Camau a awgrymir', // Needs welsh versions added to the DB
        addCommentActionDesc: 'Pa gamau sydd angen eu cymryd? Dewiswch yr opsiwn sydd fwyaf perthnasol yn y gwymplen. Gellir rhoi rhagor o fanylion yn y blwch sylwadau isod',
        addImageAction: 'Ychwanegu delwedd', // Needs welsh versions added to the DB
        addImageActionDesc: 'Os oes gennych ddelweddau perthnasol i ddangos eich sylw, llwythwch nhw yma (dim mwy na dwy ddelwedd, 10MB yr un ar y mwyaf)',
        addCommentComment: 'Sylw',
        addCommentCommentDesc: 'Defnyddiwch y maes isod i ddweud rhagor wrthym am yr ardal hon (dim mwy na 600 nod)',
        addCommentSubmit: "Cyflwyno'r Sylw",
        addCommentCovid: "Rhowch wybod i ni os yw'r sylw hwn yn ymwneud â Covid-19",
        addCommentLoading: 'Diolch am eich sylw, rydym yn eich cyfeirio ato nawr…',
        // PageLanding.vue - Contents here will be subject to the copy provided by the client, placeholders of the current layout are used in this section
        welcomeTitle: "Croeso i Fap Rhwydwaith Teithio Llesol Sir Gaerfyrddin",
        pageLandingPrefaceOne: "Mae'r map hwn ar gyfer pawb sydd am roi sylwadau ar faterion sy'n effeithio ar eu teithiau Teithio Llesol.  Yn syml, mae teithio llesol yn golygu gwneud teithiau mewn ffyrdd sy'n actif yn gorfforol - fel cerdded, symud ar olwynion (gan ddefnyddio cadair olwyn neu gymorth symudedd), beicio, neu fynd ar sgwter.  Mae Cyngor Sir Caerfyrddin yn dal i groesawu awgrymiadau ar lwybrau neu welliannau ychwanegol a fyddai'n gwella eich profiad o'r rhwydwaith a theithio o gwmpas y sir ar gyfer teithiau o ddydd i ddydd.",
        pageLandingPrefaceTwo: "Gallwch roi eich adborth drwy ychwanegu sylwadau ar y map. Erbyn hyn hefyd mae opsiwn i lwytho lluniau fel tystiolaeth i gyd-fynd â'ch sylwadau.",
        pageLandingPrefaceThree: "Bydd y sylwadau'n cael eu monitro a'u cofnodi fel sylfaen dystiolaeth a fydd yn cael ei defnyddio mewn unrhyw ddatblygiadau yn y dyfodol neu geisiadau am gyllid adeiladu. ",
        pageLandingPrefaceFour: "Mae'r map sylfaenol wedi'i ddiweddaru gyda throshaen o'r Map Rhwydwaith Teithio Llesol cymeradwy.  Mae'r map yn cynnwys:",
        pageLandingPrefaceBulletOne: "‘Llwybrau Presennol' sydd wedi'u harchwilio ac sy'n cyd-fynd â chanllawiau dylunio Teithio Llesol a; ",
        pageLandingPrefaceBulletTwo: "'Llwybrau'r Dyfodol' posib sydd wedi'u nodi yn dilyn ymgynghori â’r cyhoedd a rhanddeiliaid, ynghyd â llwybrau sydd heb eu harchwilio eto.",
        pageLandingPrefaceExtra: "Closiwch i mewn i ardal o ddiddordeb i weld y Rhwydwaith Llwybrau Presennol a Llwybrau'r Dyfodol yn fanylach. Gallwch hefyd glirio'r llwybrau hyn o'r map drwy eu dadglicio nhw yn allwedd y map.",
        pageLandingPrefaceExtraOne: "Closiwch i mewn i ardal o ddiddordeb i weld y",
        pageLandingPrefaceExtraTwo: "Rhwydwaith Llwybrau Presennol a ",
        pageLandingPrefaceExtraThree: "Llwybrau'r Dyfodol yn fanylach. Gallwch hefyd glirio'r llwybrau hyn o'r map drwy eu dadglicio nhw yn allwedd y map.",
        pageLandingCollapseOne: "Mae'r map hwn ar gyfer pawb sydd am deithio drwy gerdded neu feicio ledled ein rhanbarth, at unrhyw ddiben, gan gynnwys y rheiny nad ydynt yn cerdded neu feicio'n aml a phobl sy'n defnyddio cymhorthion symud. Hoffem gael eich sylwadau ynghylch y seilwaith sydd gennym eisoes a'r hyn yr hoffech ei weld yn y dyfodol. Wedyn bydd sylwadau'n cael eu defnyddio, ynghyd â'r rhai rydym eisoes wedi'u casglu, i gynorthwyo i gynllunio Map Rhwydwaith Teithio Llesol Sir Gaerfyrddin.",
        pageLandingCollapseTwo: "Yn dilyn Deddf Teithio Llesol (Cymru) 2013, mae'n ofynnol i bob awdurdod lleol yng Nghymru lunio mapiau o rwydweithiau Teithio Llesol. Bydd y mapiau hyn yn helpu i nodi cyfleoedd i ehangu a gwellau'r rhwydwaith a'r cyfleusterau, yn ogystal â phwysleisio'r hyn sydd wedi'i wneud yn dda. Rhaid i'r llwybrau fod yn gydlynol, yn uniongyrchol, yn gyfforddus ac yn ddeniadol.",
        pageLandingCollapseThree: "Mae ardaloedd adeiledig â phoblogaethau sy'n fwy na 2000 o bobl a'r defnydd preswyl pennaf o dir wedi'u nodi yn y Ddeddf ac fe'u dangosir ar ein map ar-lein. Er bod Cyllid Teithio Llesol wedi bod yn gyfyngedig i'r ardaloedd hyn yn hanesyddol, rydym bellach yn ceisio adborth ledled y sir i greu rhwydwaith cydlynol ar draws Sir Gaerfyrddin ac yn cysylltu ag awdurdodau cyfagos. ",
        pageLandingCollapseFour: "Gellir gweld rhagor o wybodaeth am y Ddeddf drwy fynd i: ",
        pageLandingLearnMore: 'Dysgu rhagor am y prosiect',
        // PageMap.vue
        pageMapRegisterPopupHeader: 'Diolch ichi am gofrestru cyfrif gyda ni.',
        pageMapRegisterPopupAddedComment: 'Ychwanegwyd eich sylw at y map. Gallwch bellach ychwanegu rhagor o sylwadau',
        pageMapRegisterPopupAddComment: 'Gallwch bellach ychwanegu sylwadau at y map.',
        pageMapCookiePopupHeader: "Mae'r wefan hon yn defnyddio cwcis: ",
        pageMapCookiePopupLink: 'Rhagor o wybodaeth',
        pageMapCookiePopupButton: 'Iawn, diolch',
        pageMapLegendRowOne: 'Ffin Sir Gaerfyrddin',
        pageMapLegendRowTwo: 'Ardaloedd adeiledig',
        // PageViewComment.vue - Need to apply activities and actions changes, refer to categories language update
        pageViewCommentModerated: "Nid yw'r sylw hwn wedi'i wirio gan ein cymedrolwyr eto",
        pageViewCommentShare: 'Rhannu hwn',
        pageViewCommentAction: 'Cam gweithredu',
        pageViewCommentRelate: "Mae'r sylw hwn yn ymwneud â",
        pageViewCommentWhen: "Roeddwn i'n teimlo fel hyn pan",
        pageViewCommentAdded: 'Ychwanegwyd y sylw hwn yn',
        pageViewCommentImages: 'Delwedd(au) cysylltiedig',
        pageViewImageModerated: "Nid yw'r ddelwedd ar gyfer y sylw hwn wedi'i dilysu eto",
        pageViewEnlgish: 'Saesneg',
        pageViewWelsh: 'Cymraeg',
        pageViewPhasePrefix: 'Cafodd y sylw hwn ei gyflwyno yn ystod cam ', // I should be able to use {0} to add the variable in, for some reason it's not working though
        pageViewPhaseSuffix: ' yr ymgynghoriad',
        // TheMap.vue
        mapKey: 'Allwedd Map',
        mapBuiltOn: 'Lluniwyd ar',
        mapImprove: "Gwella'r Map hwn",
        mapERN: 'Rhwydwaith Llwybrau Presennol',
        mapASR: 'Llwybrau yn yr Arfaeth',
        mapComment: 'Sylwadau',
        mapSentVN: 'Negyddol Iawn',
        mapSentQN: 'Eithaf Negyddol',
        mapSentN: 'Niwtral',
        mapSentQP: 'Eithaf Cadarnhaol',
        mapSentVP: 'Cadarnhaol Iawn',
        mapTooltipMapKey: 'Agor allwedd y map',
        mapTooltipTravel: 'Teithio i ardal',
        mapTooltipMapStyle: 'Newid dull y map',
        mapTooltipResetView: 'Ailosod golwg y map',
        mapGoTo: 'Ewchi i',
        mapCycling: 'Beicio',
        mapSharedUse: 'Rhannu Defnydd',
        mapWalking: 'Cerdded',
        mapUseMyLocation: "Defnyddio fy lleoliad",
        mapInvalidLocation: "Mae'n ymddangos bod eich lleoliad y tu allan i Sir Gaerfyrddin. Defnyddiwch y blwch chwilio.",
        // Dropzone
        dropzoneDefaultMessage: "Dodwch y ffeiliau fan hyn i'w llwytho.",
        dropzoneFallbackMessage: "Nid yw eich porwr yn cefnogi llwytho ffeiliau drwy lusgo a gollwng. ",
        dropzoneFallbackText: "Defnyddiwch y ffurflen wrth gefn isod i lwytho'ch ffeiliau fel yn y dyddiau a fu. ",
        dropzoneFileTooBig: "Mae'r ffeil yn rhy fawr ({{filesize}}MiB). Uchafswm maint ffeil: {{maxFilesize}}MiB.",
        dropzoneInvalidFileType: "Ni allwch lwytho ffeiliau o'r math hwn. ",
        dropzoneResponseError: "Ymatebodd y gweinydd gyda chôd {{statusCode}}",
        dropzoneCancelUpload: "Canslo llwytho",
        dropzoneUploadCanceled: "Wedi canslo llwytho'r ffeil",
        dropzoneCancelUploadConfirmation: "Ydych chi'n siŵr eich bod am ganslo llwytho'r ffeil hon?",
        dropzoneRemoveFile: "Dileu'r ffeil",
        dropzoneMaxFilesExceeded: "Ni allwch lwytho rhagor o ffeiliau.",
    }
};

function getLanguagCookie(doc) {
    var x = doc.split(';');
    for (var i = 0; i < x.length; i++) {
        var key = x[i].split('=')[0].trim();
        if (key == "locale") {
            return x[i].split('=')[1].trim();
        }
    }
    return null;
}

const i18n = new VueI18n({
    locale: getLanguagCookie(document.cookie) || 'en', // set locale
    messages, // set locale messages
});

export default i18n;
