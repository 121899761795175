<template>
  <div class="modal-backdrop">
    <div class="modal">
      <div>
        <img src="/images/logo.svg" id="logo"></img>
      </div>
      <div class="split welsh">
        <div class="link" @click="closeModal('cy')">
          <h3>Parhau yn Gymraeg</h3>
        </div>
      </div>
      <div class="split english">
        <div class="link" @click="closeModal('en')">
          <h3>Continue in English</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import i18n from '../../lang/i18n';

export default{
  data(){
    return {
      test: "test"
    }
  },
  methods:{
    closeModal(locale){
      document.cookie = "locale=" + locale;
      this.$emit('close');
      if(locale == 'cy'){
        location.reload();
      }
    },
    close(event){
      this.$emit('close');
    }
  }
}

</script>
