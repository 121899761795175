<template>
    <div id="map-wrapper">
        <div id="map" :class="{ wide: !wide}">
            <div class="deetu-static">
                <a href="https://deetu.com" target="_blank" aria-label="Deetu logo" rel="noopener">
                    <img src="/images/powered_deetu.png" title="Powered by Deetu"/>
                </a>
            </div>
            <div id="attribution"
                 class="mapboxgl-ctrl mapboxgl-ctrl-attrib mapboxgl-ctrl-bottom-right mapboxgl-compact">
                <a class="attrib" target="_blank" href="https://deetu.com/" rel="noopener">© Deetu </a> &nbsp;|&nbsp;
                <a class="attrib" target="_blank" href="https://www.mapbox.com/about/maps/" rel="noopener">© Mapbox </a>
                <a class="attrib" target="_blank" href="http://www.openstreetmap.org/about/" rel="noopener">©
                    OpenStreetMap </a>
                <a class="attrib" target="_blank" href="https://www.mapbox.com/map-feedback/"
                   rel="noopener"><b>{{ $t('mapImprove') }}</b></a>
            </div>
            <div id="map-geocoder" class="geocoder map-geocoder" v-show="mode !== 'comment'">
                <button id="legend-btn" :class="{selected: legendShow }" title="Show map legend"
                        @click="legendShow = !legendShow; buaShow = false; displayTooltips = false;">
                    <span class="material-icons">layers</span><span class="legend-btn-span">{{ $t('mapKey') }}</span>
                </button>
                <div id="tooltip" class="point-up" style="margin-top: 45px;" v-if="displayTooltips">
                    {{ $t('mapTooltipMapKey') }}
                    <div class="pointer up"></div>
                </div>
                <button id="legend-btn" :class="{selected: buaShow }" type="button" name="button"
                        @click="buaShow = !buaShow; legendShow = false; displayTooltips = false;">
                    <span class="material-icons">maps_home_work</span><span class="legend-btn-span">{{
                        $t('mapGoTo')
                    }}...</span>
                </button>
                <div id="tooltip" class="point-up" style="margin: 45px 140px" v-if="displayTooltips">
                    {{ $t('mapTooltipTravel') }}
                    <div class="pointer up"></div>
                </div>
            </div>
            <div v-if="legendShow" id="legend-container">
                <table>
                    <tr class="legend-element" title="Carmarthenshire boundary">
                        <td><input id="ccc-border" type="checkbox" value="ccc-border" v-model="legendData"
                                   @input="layerToggleVisibility(['ccc-border'])"><label for="ccc-border"></label></td>
                        <th class="blue-solid-line">
                            <div></div>
                        </th>
                        <td>{{ $t('pageMapLegendRowOne') }}</td>
                    </tr>
                    <tr class="legend-element" title="Built up areas">
                        <td><input id="ccc-bua" type="checkbox" value="ccc-bua" v-model="legendData"
                                   @input="layerToggleVisibility(['ccc-bua'])"><label for="ccc-bua"></label></input>
                        </td>
                        <th class="blue-dashed-line">
                            <div></div>
                        </th>
                        <td>{{ $t('pageMapLegendRowTwo') }}</td>
                    </tr>
                    <tr class="legend-element" title="Carmarthenshire boundary">
                        <td><input id="ccc-erm" type="checkbox" value="ccc-erm" v-model="legendData"
                                   @input="layerToggleVisibility(['ccc-erm'])"><label for="ccc-erm"></label></td>
                        <th class="grey-dashed-line">
                            <div></div>
                        </th>
                        <td>{{ $t('mapERN') }}</td>
                    </tr>
                    <tr class="legend-element" title="Built up areas">
                        <td><input id="ccc-asp" type="checkbox" value="ccc-asp" v-model="legendData"
                                   @input="layerToggleVisibility(['ccc-asp-shared', 'ccc-asp', 'ccc-asp-walk'])"><label
                            for="ccc-asp"></span></label></td>
                        <th>
                            <div></div>
                        </th>
                        <td>{{ $t('mapASR') }}</td>
                    </tr>
                    <tr class="legend-element" title="Shared Use">
                        <td></td>
                        <th class="green-solid-line">
                            <div></div>
                        </th>
                        <td><span>{{ $t('mapSharedUse') }}</span></td>
                    </tr>
                    <tr class="legend-element" title="Cycling">
                        <td></td>
                        <th class="orange-dashed-line">
                            <div></div>
                        </th>
                        <td><span>{{ $t('mapCycling') }}</span></td>
                    </tr>
                    <tr class="legend-element" title="Walking">
                        <td></td>
                        <th class="green-dotted-line">
                            <div></div>
                        </th>
                        <td><span>{{ $t('mapWalking') }}</span></td>
                    </tr>
                    <tr class="legend-element" title="Toggle Comments">
                        <td><input id="comments" type="checkbox" value="comments" v-model="legendData"><label
                            for="comments"></label></input></td>
                        <th></th>
                        <td><span>{{ $t('mapComment') }}</span></td>
                    </tr>
                    <tr class="legend-element" title="Very Positive">
                        <td></td>
                        <th class="green-circle">
                            <div></div>
                        </th>
                        <td><span>{{ $t('mapSentVP') }}</span></td>
                    </tr>
                    <tr class="legend-element phase-one-group" title="Quite Positive">
                        <td></td>
                        <th class="light-green-circle">
                            <div></div>
                        </th>
                        <td><span>{{ $t('mapSentQN') }}</span></td>
                    </tr>
                    <tr class="legend-element phase-one-group" title="Neutral">
                        <td></td>
                        <th class="yellow-circle">
                            <div></div>
                        </th>
                        <td><span>{{ $t('mapSentN') }}</span></td>
                    </tr>
                    <tr class="legend-element phase-one-group" title="Quite Negative">
                        <td></td>
                        <th class="orange-circle">
                            <div></div>
                        </th>
                        <td><span>{{ $t('mapSentQN') }}</span></td>
                    </tr>
                    <tr class="legend-element" title="Very Negative">
                        <td></td>
                        <th class="red-circle">
                            <div></div>
                        </th>
                        <td><span>{{ $t('mapSentVN') }}</span></td>
                    </tr>
                    <!--<tr class="legend-element">
                      <td></td>
                      <th></th>
                      <td>Comments</td>
                    </tr>
                    <div class="sentiment-legend">
                      <tr class="legend-element"><th class="dot"></th><td>Very Positive</td></tr>
                      <tr class="legend-element"><th class="dot"></th><td>Quite Positive</td></tr>
                      <tr class="legend-element"><th class="dot"></th><td>Neutral</td></tr>
                      <tr class="legend-element"><th class="dot"></th><td>Quite Negative</td></tr>
                      <tr class="legend-element"><th class="dot"></th><td>Very Negative</td></tr>
                    </div>-->
                </table>
            </div>
            <div v-if="buaShow" id="legend-container" class="bua">
                <table>
                    <tr v-for="row in buas" class="legend-element phase-one-group" title="row.name"
                        @click="buaFlyTo(row.long, row.lat, row.zoom)">
                        <td>&nbsp;&nbsp;&nbsp;&nbsp;{{ row.name }}</td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="landing-footer map">
            {{ $t('mapBuiltOn') }} <a href="https://deetu.com/" target="_blank">Deetu Engage</a> -
            <a href="mailto:engage@deetu.com?Subject=Carmarthenshire Active Travel Feedback"
               target="_top">{{ $t('mapImprove') }}</a>
        </div>

        <div id="tooltip" class="point-right"
             style="z-index: 100; background-color: white; right: 50px; top: 200px; max-width: 200px;"
             v-if="displayTooltips">
            {{ $t('mapTooltipMapStyle') }}
            <div class="pointer right"></div>
        </div>

        <div id="tooltip" class="point-right"
             style="z-index: 100; background-color: white; right: 50px; top: 240px; max-width: 200px;"
             v-if="displayTooltips">
            {{ $t('mapTooltipResetView') }}
            <div class="pointer right"></div>
        </div>
    </div>
</template>

<script>
var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
var MapboxGeocoder = require('@mapbox/mapbox-gl-geocoder');

mapboxgl.accessToken = 'pk.eyJ1Ijoiam9zaC1kZWV0dSIsImEiOiJjazVwaWN0cDEwYTJ6M2xxdm01NTV1OXRhIn0.3zsfP502LOrnO4XYM4kS2w';

import {mapState} from 'vuex';
import i18n from '../../lang/i18n';

import "mapbox-gl/dist/mapbox-gl.css";

export default {
    created: function () {
        this.$eventHub.$on('fly-to-landing-page', this.flyToLandingPage);
    },
    beforeDestroy: function () {
        this.$eventHub.$off('fly-to-landing-page', this.flyToLandingPage);
    },
    name: 'the-map',
    data() {
        return {
            // values for map center position
            mapCenter: {
                desktop: {
                    zoom: 8.85,
                    center: [-4.1365, 51.8939],
                },
                mobile: {
                    zoom: 7.5,
                    center: [-4.1365, 51.8939],
                },
            },
            hasNonDraggableMarker: false, // flag to prevent multiple markers from beeing created in comment view
            hasDraggableMarker: false, // flag to prevent multiple markers from beeing created in comment view
            map: null,
            mapLoaded: false,
            commentMarker: null,
            geocoderMapbox: null,
            geolocationButton: null,
            markers: {},
            markersOnScreen: {},
            bounds: [
                [-6.0, 51.0], // SW
                [-3.0, 53.0], // NE
            ],
            framesPerSecond: 15,
            initialOpacity: 1,
            opacity: 1,
            initialRadius: 12,
            radius: 12,
            maxRadius: 24,
            timer: null,
            colors: ['#C32121', '#C32121', '#EC671C', '#DFB436', '#B8BF08', '#75AA03'],
            colourblindList: {
                none: ['#C32121', '#C32121', '#EC671C', '#DFB436', '#B8BF08', '#75AA03'],
                protanopia: ['#C32121', '#C32121', '#ed1db9', '#5f34df', '#0836bf', '#0381ab'],
            },
            colorsForAnimation: [
                'rgba(228,77,82,0.8)',
                'rgba(228,77,82,0.8)',
                'rgba(255,149,43,0.8)',
                'rgba(255,204,51,0.8)',
                'rgba(161,218,96,0.8)',
                'rgba(93,165,84,0.8)'
            ],
            language: "en",
            localeTimer: setInterval(this.checkLanguage, 1000),
            langTitles: ["{english_na}", "{welsh_name}"],
            navLanguage: {
                en: {
                    zoomIn: 'Zoom in',
                    zoomOut: 'Zoom out',
                    resetBearing: 'Reset bearing to north',
                    showSatellite: 'Show Satellite',
                    resetMap: 'Reset Map View'
                },
                cy: {
                    zoomIn: 'Closio',
                    zoomOut: 'Pellhau',
                    resetBearing: "Ailosod y cyfeiriant i'r gogledd",
                    showSatellite: 'Dangos lluniau lloeren',
                    resetMap: 'Ailosod golwg y map'
                }
            },
            searchLang: {en: 'Search Location', cy: 'Chwilio am leoliad'},
            colourBlindModes: ['none', 'protanopia'],
            currentColourBlindMode: '',
            legendShow: false,
            legendData: ['ccc-border', 'ccc-erm', 'ccc-asp', 'comments'],
            phases: ["sentiment_data", "phase_one_data", "phase_two_data"],
            phaseLayers: ["sentiment_circle", "phase_one_layer", "phase_two_layer"],
            state: 0,
            clusterData: "sentiment_data",
            buaShow: false,
            buas: [
                {
                    "name": "Burry Port",
                    "long": -4.25652,
                    "lat": 51.68558,
                    "zoom": 13.43,
                },
                {
                    "name": "Ammanford",
                    "long": -4.04828,
                    "lat": 51.79231,
                    "zoom": 12.12,
                },
                {
                    "name": "Carmarthen",
                    "long": -4.30112,
                    "lat": 51.85629,
                    "zoom": 12.93,
                },
                {
                    "name": "Abergwili",
                    "long": -4.26871,
                    "lat": 51.86797,
                    "zoom": 15,
                },
                {
                    "name": "St Clears",
                    "long": -4.49541,
                    "lat": 51.8223,
                    "zoom": 13.45,
                },
                {
                    "name": "Llanelli",
                    "long": -4.13854,
                    "lat": 51.68454,
                    "zoom": 12.52,
                },
                {
                    "name": "Gwaun-Cae-Gurwen/Brynamman",
                    "long": -3.87412,
                    "lat": 51.79724,
                    "zoom": 12.88,
                },
                {
                    "name": "Kidwelly",
                    "long": -4.30497,
                    "lat": 51.73654,
                    "zoom": 14.26,
                },
                {
                    "name": "Glanaman",
                    "long": -3.91921,
                    "lat": 51.80389,
                    "zoom": 13.75,
                },
                {
                    "name": "Pontarddulais",
                    "long": -4.04188,
                    "lat": 51.71605,
                    "zoom": 13.78,
                },
                {
                    "name": "Llandovery",
                    "long": -3.79789,
                    "lat": 51.99563,
                    "zoom": 14.5,
                },
                {
                    "name": "Llandybie",
                    "long": -4.00178,
                    "lat": 51.81841,
                    "zoom": 13.98,
                },
                {
                    "name": "Cwmllynfell",
                    "long": -3.81385,
                    "lat": 51.79721,
                    "zoom": 14.49,
                },
                {
                    "name": "Blaenau",
                    "long": -4.028202,
                    "lat": 51.804907,
                    "zoom": 15.38,
                },
                {
                    "name": "Cwmann",
                    "long": -4.06767,
                    "lat": 52.103509,
                    "zoom": 15.13,
                }
            ],
            displayTooltips: true,
            visibleToolTips: [true],
            ermProperties: [
                {'text': 'Route: ', 'element': 'strong', 'property': 'routedbid'},
                {'element': 'p', 'property': 'comment'}
            ],
            aspProperties: [
                {'text': 'Scheme ', 'element': 'strong', 'property': 'scheme'},
                {'element': 'p', 'property': 'type'},
                {'text': 'Priority: ', 'element': 'p', 'property': 'priority'}
            ],
            aspPropertiesCY: [
                {'text': 'Cynllun ', 'element': 'strong', 'property': 'scheme'},
                {'element': 'p', 'property': 'cy_type'},
                {'text': 'Blaenoriaeth: ', 'element': 'p', 'property': 'cy_priorit'}
            ],
        }
    },
    computed: {
        ...mapState({
            mode: state => state.mode,
            commentLatLng: state => state.commentLatLng,
            wide: state => state.sidebarOpen,
        }),
        isMobile: function () {
            if (window.innerWidth <= 767) {
                return true;
            }
            return false;
        },
        currentZoom: function () {
            if (this.mapLoaded) {
                return this.map.getZoom();
            }
        }
    },
    watch: {
        mode(newValue, oldValue) {
            if (this.mapLoaded) {
                this.switchState(newValue);
            }
        },
        commentLatLng(newValue, oldValue) {
            if (this.mapLoaded) {
                if (newValue) {
                    this.moveToMarker(newValue);
                } else {
                    this.removeAnimatemarker();
                }
            }
        },
        currentZoom(newValue, oldValue) {
            if (this.mode !== 'comment') {
                if (this.hasNonDraggableMarker) {
                    this.removeNonDraggableMarker();
                    this.hasNonDraggableMarker = !this.hasNonDraggableMarker;
                }
                if (this.hasDraggableMarker) {
                    this.removeDraggableMarker();
                    this.hasDraggableMarker = !this.hasDraggableMarker;
                }
            } else {
                // condition for creating non-draggable marker
                if (newValue < 14) {
                    if (this.hasDraggableMarker) {
                        this.removeDraggableMarker();
                        this.hasDraggableMarker = !this.hasDraggableMarker;
                    }
                    if (!this.hasNonDraggableMarker) {
                        this.createNonDraggableMarker();
                        this.hasNonDraggableMarker = !this.hasNonDraggableMarker;
                    }
                } else {
                    if (this.hasNonDraggableMarker) {
                        this.removeNonDraggableMarker();
                        this.hasNonDraggableMarker = !this.hasNonDraggableMarker;
                    }
                    if (!this.hasDraggableMarker) {
                        this.createDraggableMarker();
                        this.hasDraggableMarker = !this.hasDraggableMarker;
                    }
                }
            }
        },
        legendData: function () {
            var phaseOne = this.legendData.includes('comments');
            // var phaseTwo = this.legendData.includes('comments-p2');
            //
            // // console.log(" --- PHASE ONE COMMENTS ENABLED: " + phaseOne);
            // // console.log(" --- PHASE TWO COMMENTS ENABLED: " + phaseTwo);
            //
            // if(phaseOne && phaseTwo){
            //   this.map.setLayoutProperty('sentiment_circle', 'visibility','visible');
            //   this.map.setLayoutProperty('phase_one_layer', 'visibility', 'none');
            //   this.map.setLayoutProperty('phase_two_layer', 'visibility', 'none');
            //   this.state = 3;
            // }
            // else if(phaseOne && !phaseTwo){
            //   this.map.setLayoutProperty('sentiment_circle', 'visibility','none');
            //   this.map.setLayoutProperty('phase_one_layer', 'visibility', 'visible');
            //   this.map.setLayoutProperty('phase_two_layer', 'visibility', 'none');
            //   this.state = 1;
            // }
            // else if(!phaseOne && phaseTwo){
            //   this.map.setLayoutProperty('sentiment_circle', 'visibility','none');
            //   this.map.setLayoutProperty('phase_one_layer', 'visibility', 'none');
            //   this.map.setLayoutProperty('phase_two_layer', 'visibility', 'visible');
            //   this.state = 2;
            // }
            // else{
            //   this.map.setLayoutProperty('sentiment_circle', 'visibility','none');
            //   this.map.setLayoutProperty('phase_one_layer', 'visibility', 'none');
            //   this.map.setLayoutProperty('phase_two_layer', 'visibility', 'none');
            //   this.state = 0;
            // }

            this.map.setLayoutProperty('sentiment_circle', 'visibility', phaseOne ? 'visible' : 'none');

            this.updateMarkers();
        },
        visibleToolTips: function () {
            // console.log(visibleToolTips);
        }
    },
    methods: {
        layerToggleVisibility(layer) {
            for (var i = 0; i < layer.length; i++) {
                // console.log(layer[i]);
                var visible = this.map.getLayoutProperty(layer[i], 'visibility');
                //console.log(visible);
                //console.log(layer);
                if (visible === 'visible' || visible == null) {
                    this.map.setLayoutProperty(layer[i], 'visibility', 'none');
                } else if (visible === 'none') {
                    this.map.setLayoutProperty(layer[i], 'visibility', 'visible');
                }
            }
        },
        flyToLandingPage() {
            this.map.flyTo({
                pitch: 0,
                bearing: 0,
                zoom: this.isMobile ? this.mapCenter.mobile.zoom : this.mapCenter.desktop.zoom,
                center: this.isMobile ? this.mapCenter.mobile.center : this.mapCenter.desktop.center,
                speed: 0.7
            });
            this.$router.push({name: "landing-page"}).catch(err => {
            });
        },
        switchState(mode) {
            var appViewElement = document.querySelector('#app > .view');
            appViewElement.classList.toggle(mode + "-mode");

            if (mode === 'view') {
                this.map.setLayoutProperty('sentiment_circle', 'visibility', 'visible');
                if (this.hasNonDraggableMarker) {
                    this.removeNonDraggableMarker();
                    this.hasNonDraggableMarker = !this.hasNonDraggableMarker;
                }
                if (this.hasDraggableMarker) {
                    this.removeDraggableMarker();
                    this.hasDraggableMarker = !this.hasDraggableMarker;
                }
                appViewElement.classList.remove("comment-mode");
                appViewElement.classList.add("view-mode");
            } else {
                this.map.setLayoutProperty('sentiment_circle', 'visibility', 'none');
                this.legendShow = false;
                this.removeAnimatemarker();
                if (this.currentZoom >= 14) {
                    this.createDraggableMarker();
                    this.hasDraggableMarker = !this.hasDraggableMarker;
                } else {
                    this.createNonDraggableMarker();
                    this.hasNonDraggableMarker = !this.hasNonDraggableMarker;
                }
                this.createGeocoder();
                appViewElement.classList.add("comment-mode");
                appViewElement.classList.remove("view-mode");
            }
        },
        createDonutChart(props) {
            var offsets = [];
            var counts = [props.sent1, props.sent2, props.sent3, props.sent4, props.sent5];
            var total = 0;
            for (var i = 0; i < counts.length; i++) {
                offsets.push(total);
                total += counts[i];
            }
            var fontSize = total >= 1000 ? 16 : total >= 100 ? 20 : 16;
            var r = total >= 1000 ? 40 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
            var r0 = Math.round(r * 0.6);
            var w = r * 2;

            var html = '<svg width="' + w + '" height="' + w + '" viewbox="0 0 ' + w + ' ' + w +
                '" text-anchor="middle" class="donut" style="font: ' + fontSize + 'px sans-serif">';

            for (i = 0; i < counts.length; i++) {
                html += this.donutSegment(offsets[i] / total, (offsets[i] + counts[i]) / total, r, r0, this.colors[i + 1]);
            }
            html += '<circle cx="' + r + '" cy="' + r + '" r="' + r0 +
                '" fill="#f6f6f4" /><text dominant-baseline="central" fill="#5f625f" class="donut-text" transform="translate(' +
                r + ', ' + r + ')">' + total.toLocaleString() + '</text></svg>';

            var el = document.createElement('div');
            el.innerHTML = html;
            return el.firstChild;

        },
        donutSegment(start, end, r, r0, color) {
            if (end - start === 1) end -= 0.00001;
            var a0 = 2 * Math.PI * (start - 0.25);
            var a1 = 2 * Math.PI * (end - 0.25);
            var x0 = Math.cos(a0), y0 = Math.sin(a0);
            var x1 = Math.cos(a1), y1 = Math.sin(a1);
            var largeArc = end - start > 0.5 ? 1 : 0;

            return ['<path d="M', r + r0 * x0, r + r0 * y0, 'L', r + r * x0, r + r * y0,
                'A', r, r, 0, largeArc, 1, r + r * x1, r + r * y1,
                'L', r + r0 * x1, r + r0 * y1, 'A',
                r0, r0, 0, largeArc, 0, r + r0 * x0, r + r0 * y0,
                '" fill="' + color + '" />'].join(' ');
        },
        updateMarkers() {
            // TODO - Figue out how to make the on click event work again, currently not functioning error: invalid lng/lat
            //console.log("UPDATE MARKERS");
            var newMarkers = {};
            var sourceFilter = null;
            var features = this.map.querySourceFeatures('sentiment_data');

            // if(this.state != 3){
            //   sourceFilter = ["==", "phase", this.state];
            // }

            // if(sourceFilter){
            //   features = this.map.querySourceFeatures('sentiment_data',{
            //      filter: sourceFilter,
            //    });
            // }
            // else{
            //   features = this.map.querySourceFeatures('sentiment_data');
            // }
            //console.log(features);

            // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
            // and add it to the map if it's not there already

            if (this.legendData.includes('comments')) {
                for (var i = 0; i < features.length; i++) {
                    var coords = features[i].geometry.coordinates;
                    var props = features[i].properties;

                    // console.log(features[i].properties);

                    if (!props.cluster) continue;
                    var id = props.cluster_id;

                    var marker = this.markers[id];
                    if (!marker) {
                        let el = this.createDonutChart(props);
                        marker = this.markers[id] = new mapboxgl.Marker({element: el}).setLngLat(coords);
                        // Cluster click events

                        el.addEventListener('click', e => {
                            e.stopPropagation();
                            e.preventDefault();

                            // get the position in px from translation
                            let markerCoords = e.target.style.transform
                                .split('translate')[2]
                                .split(')')[0]
                                .replace(/[{()}]/g, '')
                                .replace(/[{px}]/g, '')
                                .split(',');

                            //console.log(e.target.style.transform);
                            //console.log(markerCoords);

                            let currentZoom = this.map.getZoom();

                            this.map.flyTo({
                                zoom: currentZoom + 2,
                                pitch: 0,
                                bearing: 0,
                                // Convert above px into map coordinates
                                center: this.map.unproject(markerCoords),
                                speed: 0.7
                            });
                        });
                    }
                    newMarkers[id] = marker;

                    if (!this.markersOnScreen[id]) {
                        marker.addTo(this.map);
                    }
                }
            }
            // for every marker we've added previously, remove those that are no longer visible
            for (id in this.markersOnScreen) {
                if (!newMarkers[id]) {
                    this.markersOnScreen[id].remove();
                }
            }
            this.markersOnScreen = newMarkers;
        },
        createGeocoder() {
            let geocoder = document.getElementById('geocoder');

            // if already created destroy and recreated innerElement
            if (geocoder.innerHTML !== '') {
                geocoder.innerHTML = '';
            }
            this.geocoderMapbox = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                marker: false,
                bbox: [-6.0, 51.0, -3.0, 53.0],
                placeholder: "Search location",
                minLength: 3,
                limit: 15,
                zoom: 17,
                types: 'country,region,postcode,district,place,locality,neighborhood,address',
                enableGeolocation: true,
                addressAccuracy: "street"
            });


            if (typeof navigator.geolocation !== "undefined") {
                if(this.geolocationButton === null && document.querySelector('#geolocation') === null){
                    this.createGeoButton();
                }

                const geolocateControl = new mapboxgl.GeolocateControl({
                    positionOptions: {
                        enableHighAccuracy: true
                    },
                    // When active the map will receive updates to the device's location as it changes.
                    trackUserLocation: true,
                    // Draw an arrow next to the location dot to indicate which direction the device is heading.
                    showUserHeading: true
                });

                this.map.addControl(geolocateControl);

                geolocateControl.on('outofmaxbounds', () => {
                    alert(i18n.messages[i18n.locale].mapInvalidLocation);

                    (document.querySelectorAll('#geolocation') ?? []).forEach(el => {
                        el.style.display = "none";
                    });

                    this.map.removeControl(geolocateControl);
                });
            }

            document.getElementById('geocoder').appendChild(this.geocoderMapbox.onAdd(this.map));

            this.geocoderMapbox.on('result', (result) => {
                ////console.log(result.result);
                this.commentMarker.setLngLat(result.result.center);
                this.$store.commit('updateLatLng', this.commentMarker.getLngLat());
            });
        },
        createGeoButton() {
            this.geolocationButton = document.createElement("button");
            this.geolocationButton.setAttribute("id", "geolocation");
            this.geolocationButton.innerText = i18n.messages[i18n.locale].mapUseMyLocation;
            this.geolocationButton.addEventListener("click", this.initGeoSearch.bind(this));

            this.map.on('moveend', () => {
                this.geolocationButton.disabled = false;
            });

            this.$eventHub.$on("language-switch", msg => {
                this.geolocationButton.innerText = i18n.messages[i18n.locale].mapUseMyLocation;
            });

            document.getElementById('geocoder').insertAdjacentElement("afterend", this.geolocationButton)
        },
        initGeoSearch() {
            this.geolocationButton.disabled = true;
            navigator.geolocation.getCurrentPosition(
                this.onGeoSuccess.bind(this),
                () => {
                    this.onGeoError("An error occurred");
                }
            );
        },
        onGeoSuccess(position) {
            const { latitude, longitude } = position.coords;

            const southWest = new mapboxgl.LngLat(this.bounds[0][0], this.bounds[0][1]);
            const northEast = new mapboxgl.LngLat(this.bounds[1][0], this.bounds[1][1]);
            const bounds = new mapboxgl.LngLatBounds(southWest, northEast);

            const isUserInBbx = bounds.contains(new mapboxgl.LngLat(longitude, latitude));

            if(isUserInBbx) {
                this.map.flyTo({
                    zoom: 18.9,
                    pitch: 0,
                    bearing: 0,
                    center: new mapboxgl.LngLat(longitude, latitude),
                    speed: 0.9
                });

                this.getAreaLabel(longitude, latitude);
            } else {
                this.onGeoError(i18n.messages[i18n.locale].mapInvalidLocation)
            }
        },
        onGeoError(errorMessage) {
            const message = errorMessage ?? "An error occurred";

            this.geolocationButton.classList.add('error');
            this.geolocationButton.innerText = message;
            this.geolocationButton.disabled = true;
            this.geolocationButton.removeEventListener('click', this.initGeoSearch);
        },
        getAreaLabel(lng, lat) {
            const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?limit=1&types=place&access_token=${mapboxgl.accessToken}`;

            fetch(url, {
                method: 'GET',
            })
            .then((resp) => resp.json())
            .then((data) => {
                const text = data.features[0].text ?? false;

                if (text) {
                    document.querySelector("#geocoder .mapboxgl-ctrl-geocoder--input").value = text;
                }
            });
        },
        createNonDraggableMarker() {
            var popupWidth = '370px';
            if (this.isMobile) {
                popupWidth = '280px';
            }

            if (this.language == 'en') {
                var popup = new mapboxgl.Popup({offset: 20, closeButton: false})
                    .setHTML("<div class='form-item'><div class='num'>2</div><div><label>Zoom in</label><p>Please zoom in to mark the location of your comment.</p></div></div>")
                    .setMaxWidth(popupWidth);
            } else {
                var popup = new mapboxgl.Popup({offset: 20, closeButton: false})
                    .setHTML("<div class='form-item'><div class='num'>2</div><div><label>Closio</label><p>Gofynnir i chi glosio i farcio lleoliad eich sylw</p></div></div>")
                    .setMaxWidth(popupWidth);
            }

            var marker = this.commentMarker = new mapboxgl.Marker({
                color: '#8A8D8E',
                draggable: false,
            }).setLngLat(this.map.getCenter()).setPopup(popup).addTo(this.map);

            this.commentMarker.togglePopup();

            this.map.on('move', function (e) {
                let currentCenter = e.target.getCenter();
                marker.setLngLat(currentCenter);
            });

            var popupElements = document.getElementsByClassName('mapboxgl-popup-content');
            if (popupElements.length > 0) {
                popupElements[0].setAttribute('tabindex', '1');
            }
        },
        removeNonDraggableMarker() {
            if (this.commentMarker) {
                this.commentMarker.remove();
                this.commentMarker = null;
            }
        },
        createDraggableMarker() {
            var popupWidth = '370px';
            if (this.isMobile) {
                popupWidth = '280px';
            }

            if (this.language == 'en') {
                var popup = new mapboxgl.Popup({offset: 20, closeButton: false})
                    .setHTML("<div class='form-item'><div class='num'>2</div><div><label>Mark the location</label><p>Please move the map pin to mark the location of your comment.</p></div></div>")
                    .setMaxWidth(popupWidth);
            } else {
                var popup = new mapboxgl.Popup({offset: 20, closeButton: false})
                    .setHTML("<div class='form-item'><div class='num'>2</div><div><label>Marcio'r lleoliad</label><p>Symudwch y pin ar y map i farcio lleoliad eich sylw</p></div></div>")
                    .setMaxWidth(popupWidth);
            }

            this.commentMarker = new mapboxgl.Marker({
                color: '#27348B',
                draggable: true
            }).setLngLat(this.map.getCenter()).setPopup(popup).addTo(this.map);

            this.commentMarker.togglePopup();

            this.commentMarker.on('dragend', (e) => {
                this.$store.commit('updateLatLng', e.target.getLngLat());
            });

            ////console.log(document.getElementsByClassName('mapboxgl-marker'));
            document.getElementsByClassName('mapboxgl-marker')[0].setAttribute('tabindex', 0);
            var popupElements = document.getElementsByClassName('mapboxgl-popup-content');
            if (popupElements.length > 0) {
                popupElements[0].setAttribute('role', 'region');
            }
        },
        removeDraggableMarker() {
            if (this.commentMarker) {
                this.commentMarker.remove();
                this.commentMarker = null;
            }
        },
        animateMarker() {
            this.timer = setInterval(function () {

                this.radius += (this.maxRadius - this.radius) / this.framesPerSecond;
                this.opacity -= (.9 / this.framesPerSecond);

                if (this.opacity <= 0) {
                    this.radius = this.initialRadius;
                    this.opacity = this.initialOpacity;
                }

                this.map.setPaintProperty('point', 'circle-radius', this.radius);
                this.map.setPaintProperty('point', 'circle-opacity', this.opacity);
            }.bind(this), 1000 / this.framesPerSecond);
        },
        removeAnimatemarker() {
            if (this.map.getLayer('point')) {
                this.map.removeLayer('point');
                this.map.removeSource('point');
            }

            while (this.timer) {
                window.clearInterval(this.timer);
                this.timer--;
            }

            this.radius = this.initialRadius;
            this.opacity = this.initialOpacity;
        },
        moveToMarker(marker) {
            if (!this.map.getSource('point')) {
                this.map.addSource('point', {
                    "type": "geojson",
                    "data": {
                        "type": "Point",
                        "coordinates": marker.location.coordinates
                    }
                });

                this.map.addLayer({
                    "id": "point",
                    "source": "point",
                    "type": "circle",
                    "paint": {
                        "circle-radius": this.initialRadius,
                        "circle-radius-transition": {duration: 0},
                        "circle-opacity-transition": {duration: 0},
                        "circle-color": this.colorsForAnimation[marker.sentiment],
                    }
                });

                setTimeout(this.animateMarker(), 0);
            }

            this.map.flyTo({
                zoom: 18.9,
                pitch: 0,
                bearing: 0,
                center: marker.location.coordinates,
                speed: 0.9
            });
        },
        checkLanguage() {
            if (this.language != i18n.locale) {
                this.language = i18n.locale;
                this.map.setLayoutProperty('english-welsh-village', 'text-field', this.langTitles[this.language == 'en' ? 0 : 1]);
                this.map.setLayoutProperty('english-welsh-town', 'text-field', this.langTitles[this.language == 'en' ? 0 : 1]);
                var navTools = document.getElementsByClassName('mapboxgl-ctrl-group');
                for (var i = 0; i < navTools.length; i++) {
                    var children = navTools[i].childNodes;
                    for (var j = 0; j < children.length; j++) {
                        switch (children[j].title) {
                            case this.navLanguage.en.zoomIn:
                            case this.navLanguage.cy.zoomIn:
                                children[j].title = this.language == 'en' ? this.navLanguage.en.zoomIn : this.navLanguage.cy.zoomIn;
                                break;
                            case this.navLanguage.en.zoomOut:
                            case this.navLanguage.cy.zoomOut:
                                children[j].title = this.language == 'en' ? this.navLanguage.en.zoomOut : this.navLanguage.cy.zoomOut;
                                break;
                            case this.navLanguage.en.resetBearing:
                            case this.navLanguage.cy.resetBearing:
                                children[j].title = this.language == 'en' ? this.navLanguage.en.resetBearing : this.navLanguage.cy.resetBearing;
                                break;
                            case this.navLanguage.en.showSatellite:
                            case this.navLanguage.cy.showSatellite:
                                children[j].title = this.language == 'en' ? this.navLanguage.en.showSatellite : this.navLanguage.cy.showSatellite;
                                break;
                            case this.navLanguage.en.resetMap:
                            case this.navLanguage.cy.resetMap:
                                children[j].title = this.language == 'en' ? this.navLanguage.en.resetMap : this.navLanguage.cy.resetMap;
                                break;
                        }
                    }
                }
                var searchBar = document.getElementsByClassName('mapboxgl-ctrl-geocoder--input');
                for (var i = 0; i < searchBar.length; i++) {
                    searchBar[i].placeholder = this.language == 'en' ? this.searchLang.en : this.searchLang.cy;
                }
            }
        },
        checkColourBlindMode() {
            // Method for checking specifically the colourblind value on the cookie
            var value;
            ////console.log(document.cookie);
            var cookie = document.cookie;

            var cookieAttributes = cookie.split(';');

            ////console.log(cookieAttributes);

            for (var i = 0; i < cookieAttributes.length; i++) {
                ////console.log(cookieAttributes[i].split('=')[0].trim());
                if (cookieAttributes[i].split('=')[0].trim() == "colourblind_mode") {
                    return cookieAttributes[i].split('=')[1].trim();
                }
            }

            return "none";
        },
        toggleMapLegend() {
            // Open and close map legend on button click
            $('#legend-container').css('display', 'table');
            $(this).removeClass('legend-btn-closed');
            $(this).attr('title', 'Hide map legend');
            $('#legend-container').css('display', 'none');
            $(this).addClass('legend-btn-closed');
            $(this).attr('title', 'Show map legend');
        },
        logData() {
            // console.log(window.mapFeatures);
            // console.log(window.phaseOne);
            // console.log(window.phaseTwo);
        },
        buaFlyTo(long, lat, zoom) {
            // console.log(long);
            // console.log(lat);
            // console.log(zoom);
            this.map.flyTo({
                zoom: zoom,
                pitch: 0,
                bearing: 0,
                center: [long, lat],
                speed: 0.9
            });
        },
        generatePopup(props, popup) {
            // console.log("PROPS:");
            // console.log(props);
            // console.log("POPUP:");
            // console.log(popup);
            var element = document.createElement('div');

            var div = d3.select(element);

            for (var i = 0; i < popup.length; i++) {
                // console.log(popup[i].property);
                // console.log(props[popup[i].property]);
                var text = "";
                if (popup[i].text) {
                    text = popup[i].text;
                }

                if (props[popup[i].property]) {
                    if (text.includes("[]")) {
                        text = text.replace("[]", props[popup[i].property]);
                    } else {
                        text += props[popup[i].property];
                    }
                }
                switch (popup[i].element) {
                    case 'a':
                        if (popup[i].href || popup[i].hrefprop) {
                            div.append(popup[i].element)
                                .attr('href', popup[i].hrefprop || popup[i].href)
                                .attr('target', '_blank')
                                .text(text);
                        } else {
                            // console.log('Missing href attribute in array location: ' + i);
                        }
                        break;
                    default:
                        div.append(popup[i].element)
                            .text(text);
                        break;
                }
            }

            return element.innerHTML;
        },
        showHideTooltip(i, state) {
            this.visibleToolTips[i] = state;
        }
    },
    mounted() {
        this.map = new mapboxgl.Map({
            container: 'map',
            minZoom: 7,
            maxZoom: 18.1,
            maxBounds: this.bounds,
            zoom: this.isMobile ? this.mapCenter.mobile.zoom : this.mapCenter.desktop.zoom,
            center: this.isMobile ? this.mapCenter.mobile.center : this.mapCenter.desktop.center,
            attributionControl: false,
            style: 'mapbox://styles/josh-deetu/clemoy6ps003101pct0ty593o',
            hash: false,
        });

        this.logData();

        var that = this;

        var _createClass = (function () {
            function defineProperties(target, props) {
                for (var i = 0; i < props.length; i++) {
                    var descriptor = props[i];
                    descriptor.enumerable = descriptor.enumerable || false;
                    descriptor.configurable = true;
                    if ("value" in descriptor) descriptor.writable = true;
                    Object.defineProperty(target, descriptor.key, descriptor);
                }
            }

            return function (Constructor, protoProps, staticProps) {
                if (protoProps) defineProperties(Constructor.prototype, protoProps);
                if (staticProps) defineProperties(Constructor, staticProps);
                return Constructor;
            };
        })();

        function _classCallCheck(instance, Constructor) {
            if (!(instance instanceof Constructor)) {
                throw new TypeError("Cannot call a class as a function");
            }
        }

        var StyleToggle = (function () {
            function StyleToggle(_ref) {
                _classCallCheck(this, StyleToggle);
            }

            _createClass(StyleToggle, [
                {
                    key: "onAdd",
                    value: function onAdd(map) {
                        this._map = map;
                        var _this = this;

                        this._btn = document.createElement("button");
                        this._btn.type = "button";
                        this._btn.className = "mapboxgl-ctrl-icon satellite-style-button";
                        this._btn["aria-label"] = "Satellite Toggle";
                        this._btn.title = "Show Satellite";

                        var labels = ['waterway-label', 'road_major_label number',
                            'road_major_label name', 'road_major_label street',
                            'poi_label', 'place_label_other', 'place_label_cities',
                            'place_label_towns', 'country_label'];

                        function setSatelliteStyle(map) {
                            // Map Layers
                            map.setPaintProperty('mapbox-satellite', 'raster-opacity', 1);
                            map.setPaintProperty('tunnel_minor', 'line-color', 'rgba(227,227,227,0.3)');
                            map.setPaintProperty('tunnel_major', 'line-color', 'rgba(227,227,227,0.3)');
                            map.setPaintProperty('road_minor', 'line-color', 'rgba(227,227,227,0.3)');
                            map.setPaintProperty('road_major', 'line-color', 'rgba(242,222,156,0.6)');
                            map.setPaintProperty('bridge_minor case', 'line-color', 'rgba(227,227,227,0.3)');
                            map.setPaintProperty('bridge_major case', 'line-color', 'rgba(242,222,156,0.3)');
                            map.setPaintProperty('bridge_minor', 'line-color', 'rgba(227,227,227,0.3)');
                            map.setPaintProperty('bridge_major', 'line-color', 'rgba(242,222,156,0.6)');
                            map.setPaintProperty('rail_minor', 'line-color', 'rgba(227,227,227,0.3)');
                            map.setPaintProperty('rail_major', 'line-color', 'rgba(242,222,156,0.6)');
                            map.setPaintProperty('scr-border', 'line-color', '#463960');
                            map.setPaintProperty('scr-mask', 'fill-color', 'rgba(113, 110, 119, 0.6)');

                            // Map Label Layers
                            for (var i = 0; i < labels.length; i++) {
                                map.setPaintProperty(labels[i], 'text-color', '#ffffff');
                                map.setPaintProperty(labels[i], 'text-halo-color', '#2b2b2b');
                            }
                            map.setPaintProperty('waterway-label', 'text-color', '#75cff0');
                            map.setPaintProperty('waterway-label', 'text-halo-width', 0);
                            map.setPaintProperty('poi_label', 'text-color', '#afed82');
                            map.setPaintProperty('country_label', 'text-halo-color', '#050505');
                        }

                        function setLightStyle(map) {
                            // Map Layers
                            map.setPaintProperty('mapbox-satellite', 'raster-opacity', 0);
                            map.setPaintProperty('tunnel_minor', 'line-color', 'rgba(240,240,240,1)');
                            map.setPaintProperty('tunnel_major', 'line-color', 'rgba(240,240,240,1)');
                            map.setPaintProperty('road_minor', 'line-color', 'rgba(255,255,255,1)');
                            map.setPaintProperty('road_major', 'line-color', 'rgba(255,255,255,1)');
                            map.setPaintProperty('bridge_minor case', 'line-color', 'rgba(255,255,255,0.5)');
                            map.setPaintProperty('bridge_major case', 'line-color', 'rgba(255,255,255,0.5)');
                            map.setPaintProperty('bridge_minor', 'line-color', 'rgba(255,255,255,1)');
                            map.setPaintProperty('bridge_major', 'line-color', 'rgba(255,255,255,1)');
                            map.setPaintProperty('rail_minor', 'line-color', 'rgba(230,230,230,1)');
                            map.setPaintProperty('rail_major', 'line-color', 'rgba(230,230,230,1)');
                            map.setPaintProperty('scr-border', 'line-color', '#aea5c0');
                            map.setPaintProperty('scr-mask', 'fill-color', 'rgba(228, 226, 233, 0.6)');

                            // Map Label Layers
                            for (var i = 0; i < labels.length; i++) {
                                map.setPaintProperty(labels[i], 'text-color', '#4e315e');
                                map.setPaintProperty(labels[i], 'text-halo-color', 'rgba(255,255,255,0.75)');
                            }
                            map.setPaintProperty('road_major_label number', 'text-halo-color', 'rgba(255,255,255,1)');
                        }

                        this._btn.onclick = function () {
                            var mapSatelliteOpacity = map.getPaintProperty('mapbox-satellite', 'raster-opacity');

                            // if Light, switch to Satellite (sat opacity = 1)
                            // else if Satellite, switch to Light (sat opacity = 0)
                            if (mapSatelliteOpacity == 0) {
                                setSatelliteStyle(map);
                                this.className = "mapboxgl-ctrl-icon light-style-button";
                                this.title = "Hide Satellite";
                            } else if (mapSatelliteOpacity == 1) {
                                setLightStyle(map);
                                this.className = "mapboxgl-ctrl-icon satellite-style-button";
                                this.title = "Show Satellite";
                            }
                            that.displayTooltips = false;
                        };

                        this._btn.onmouseover = function () {
                            // console.log("HOVER");
                            that.showHideTooltip(0, true);
                        };

                        this._btn.onmouseleave = function () {
                            // console.log("LEAVE");
                            that.showHideTooltip(0, false);
                        }

                        this._container = document.createElement("div");
                        this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
                        this._container.appendChild(this._btn);

                        return this._container;
                    }
                },
                {
                    key: "onRemove",
                    value: function onRemove() {
                        this._container.parentNode.removeChild(this._container);
                        this._map = undefined;
                    }
                }
            ]);

            return StyleToggle;
        })();

        // Reset map view and url
        var HomeButton = (() => {
            function HomeButton(_ref) {
                _classCallCheck(this, HomeButton);
            }

            _createClass(HomeButton, [
                {
                    key: "onAdd",
                    value: function onAdd(map) {
                        this._map = map;
                        var _this = this;
                        this._btn = document.createElement("button");
                        this._btn.type = "button";
                        this._btn.className = "mapboxgl-ctrl-icon home-button";
                        this._btn["aria-label"] = "Reset Map View";
                        this._btn.title = "Reset Map View";
                        this._btn.onclick = function () {
                            that.flyToLandingPage();
                        };

                        this._btn.onmouseover = function () {
                            // console.log("HOVER");
                            that.showHideTooltip(0, true);
                        };

                        this._btn.onmouseleave = function () {
                            // console.log("LEAVE");
                            that.showHideTooltip(0, false);
                        }

                        this._container = document.createElement("div");
                        this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
                        this._container.appendChild(this._btn);

                        return this._container;
                    }
                },
                {
                    key: "onRemove",
                    value: function onRemove() {
                        this._container.parentNode.removeChild(this._container);
                        this._map = undefined;
                    }
                }
            ]);

            return HomeButton;
        })();

        var colourBlindToggle = (() => {
            function colourBlindToggle(_ref) {
                _classCallCheck(this, colourBlindToggle);
            }

            _createClass(colourBlindToggle, [
                {
                    key: "onAdd",
                    value: function onAdd(map) {
                        this._map = map;
                        var _this = this;
                        this._btn = document.createElement("button");
                        this._btn.type = "button";
                        this._btn.className = "mapboxgl-ctrl-icon accessible-button";
                        this._btn["aria-label"] = "Change Colourblind Mode";
                        this._btn.title = "Change Colourblind Mode";
                        this._btn.onclick = function () {
                            // Possible map style changes
                            if (that.currentColourBlindMode != '') {

                            } else {

                            }
                            var mode = that.checkColourBlindMode();
                            if (mode == 'none') {

                            }

                            ////console.log(that.colourblindList[mode]);
                            ////console.log(that.currentColourBlindMode);
                        };

                        this._container = document.createElement("div");
                        this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
                        this._container.appendChild(this._btn);

                        return this._container;
                    }
                },
                {
                    key: "onRemove",
                    value: function onRemove() {
                        this._container.parentNode.removeChild(this._container);
                        this._map = undefined;
                    }
                }
            ]);

            return colourBlindToggle;
        })();

        this.map.addControl(new mapboxgl.NavigationControl());
        this.map.addControl(new StyleToggle({}));
        this.map.addControl(new HomeButton({}));
        //this.map.addControl(new colourBlindToggle({}));

        // geocoder on the map #map-geocoder
        var geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            marker: false,
            bbox: [-6.0, 51.0, -3.0, 53.0],
            placeholder: "Search location",
            minLength: 3,
            limit: 15,
            zoom: 17,
            types: 'country,region,postcode,district,place,locality,neighborhood,address',
        });
        document.getElementById('map-geocoder').appendChild(geocoder.onAdd(this.map));

        if (typeof navigator.geolocation !== "undefined" && this.mode === 'comment') {
            // fix for movement of map element...
            if(document.querySelector('#geolocation') !== null) {
                document.querySelector('#geolocation').remove();
            }

            this.createGeoButton();

            const geolocateControl = new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                // When active the map will receive updates to the device's location as it changes.
                trackUserLocation: true,
                // Draw an arrow next to the location dot to indicate which direction the device is heading.
                showUserHeading: true
            });

            this.map.addControl(geolocateControl);

            geolocateControl.on('outofmaxbounds', () => {
                alert(i18n.messages[i18n.locale].mapInvalidLocation);

                (document.querySelectorAll('#geolocation') ?? []).forEach(el => {
                    el.style.display = "none";
                });

                this.map.removeControl(geolocateControl);
            });

            geolocateControl.on('geolocate', (position) => {
                const { latitude, longitude } = position.coords;
                const lngLat = new mapboxgl.LngLat(longitude, latitude)

                this.commentMarker.setLngLat(lngLat);
                this.$store.commit('updateLatLng', lngLat);
            });
        }

        this.map.on('load', () => {
            var sent1 = ["==", ["get", "sentiment"], 1];
            var sent2 = ["==", ["get", "sentiment"], 2];
            var sent3 = ["==", ["get", "sentiment"], 3];
            var sent4 = ["==", ["get", "sentiment"], 4];
            var sent5 = ["==", ["get", "sentiment"], 5];
            var phase1 = ["==", ["get", "phase"], 1];
            var phase2 = ["==", ["get", "phase"], 2];

            this.map.addSource('sentiment_data', {
                "type": "geojson",
                "data": window.mapFeatures,
                "cluster": true,
                "clusterMaxZoom": 15,
                "clusterRadius": 45,
                "clusterProperties": {
                    "sent1": ["+", ["case", sent1, 1, 0]],
                    "sent2": ["+", ["case", sent2, 1, 0]],
                    "sent3": ["+", ["case", sent3, 1, 0]],
                    "sent4": ["+", ["case", sent4, 1, 0]],
                    "sent5": ["+", ["case", sent5, 1, 0]]
                }
            });
            this.map.addLayer({
                "id": "ccc-bua",
                "type": "line",
                "source": {
                    type: 'vector',
                    url: 'mapbox://josh-deetu.0yrruusa'
                },
                "source-layer": "ntd3038_ccc_bua_200103-6gqzk5",
                "layout": {
                    'visibility': 'none',
                },
                "paint": {
                    "line-color": "#afb7e9",
                    "line-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        0, 1,
                        11, 1.5,
                        15, 2
                    ],
                    "line-dasharray": [4, 1]
                }
            }, 'ccc-erm');

            this.map.addLayer({
                "id": "sentiment_circle",
                "type": "circle",
                "source": "sentiment_data",
                "filter": ["!=", "cluster", true],
                "paint": {
                    "circle-color": ["case",
                        sent1, this.colors[1],
                        sent2, this.colors[2],
                        sent3, this.colors[3],
                        sent4, this.colors[4], this.colors[5]],
                    "circle-opacity": 0.8,
                    "circle-radius": 12,
                }
            });

            // this.map.addLayer({
            //     "id": "phase_one_layer",
            //     "type": "circle",
            //     "source": "sentiment_data",
            //     "layout": {
            //       "visibility": "none",
            //     },
            //     "filter": [ 'all',
            //         ["!=", "cluster", true],
            //         ["==", "phase", 1]
            //       ],
            //     "paint": {
            //         "circle-color": ["case",
            //             sent1, this.colors[1],
            //             sent2, this.colors[2],
            //             sent3, this.colors[3],
            //             sent4, this.colors[4], this.colors[5]],
            //         "circle-opacity": 0.8,
            //         "circle-radius": 12,
            //     }
            // });
            //
            // this.map.addLayer({
            //     "id": "phase_two_layer",
            //     "type": "circle",
            //     "source": "sentiment_data",
            //     "layout": {
            //       "visibility": "none",
            //     },
            //     "filter": [ 'all',
            //         ["!=", "cluster", true],
            //         ["==", "phase", 2]
            //       ],
            //     "paint": {
            //         "circle-color": ["case",
            //             sent1, this.colors[1],
            //             sent2, this.colors[2],
            //             sent3, this.colors[3],
            //             sent4, this.colors[4], this.colors[5]],
            //         "circle-opacity": 0.8,
            //         "circle-radius": 12,
            //     }
            // });

            this.map.setLayoutProperty('ccc-inm', 'visibility', 'none');

            // TODO - Add multiple layers with the following changes
            // Cycling - Dashed Orange (current)
            // Walking - Dotted green
            // Shared Use - Dashed green

            this.map.addLayer({
                "id": "ccc-asp-shared",
                "type": "line",
                "source": {
                    type: 'vector',
                    url: 'mapbox://josh-deetu.b6q7r28u'
                },
                "source-layer": "ntd2038_future_routes_all-0id1nx",
                "layout": {
                    'visibility': 'visible',
                },
                "paint": {
                    "line-color": [
                        "match",
                        ["get", "type"],
                        "Cycling", "#f48342",
                        "Walking and Cycling", "#5da554",
                        "#000000"
                    ],
                    "line-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        0, 1,
                        11, 2,
                        15, 3
                    ]
                },
                "filter": ["any",
                    ["==", "type", "Walking and Cycling"]
                ],
            }, 'ccc-erm');

            this.map.addLayer({
                "id": "ccc-asp",
                "type": "line",
                "source": {
                    type: 'vector',
                    url: 'mapbox://josh-deetu.b6q7r28u'
                },
                "source-layer": "ntd2038_future_routes_all-0id1nx",
                "layout": {
                    'visibility': 'visible',
                },
                "paint": {
                    "line-color": [
                        "match",
                        ["get", "type"],
                        "Cycling", "#f48342",
                        "Shared Use", "#5da554",
                        "#000000"
                    ],
                    "line-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        0, 1,
                        11, 2,
                        15, 3
                    ],
                    "line-dasharray": [4, 1]
                },
                "filter": ["any",
                    ["==", "type", "Cycling"]
                ],
            }, 'ccc-erm');

            this.map.addLayer({
                "id": "ccc-asp-walk",
                "type": "line",
                "source": {
                    type: 'vector',
                    url: 'mapbox://josh-deetu.b6q7r28u'
                },
                "source-layer": "ntd2038_future_routes_all-0id1nx",
                "layout": {
                    'visibility': 'visible',
                },
                "paint": {
                    "line-color": "#5da554",
                    "line-width": [
                        "interpolate",
                        ["linear"],
                        ["zoom"],
                        0, 1,
                        11, 2,
                        15, 3
                    ],
                    "line-dasharray": [1, 1]
                },
                "filter": ["any",
                    ["==", "type", "Walking"],
                    ["==", "type", "Pedestrian"]
                ],
            }, 'ccc-erm');

            this.checkLanguage();

            this.mapLoaded = true;

            if (this.commentLatLng !== null) {
                this.moveToMarker(this.commentLatLng);
            }

            this.switchState(this.$store.state.mode);

            // Change the cursor to a pointer when the mouse is over marker
            this.map.on('mouseenter', 'sentiment_circle', () => {
                this.map.getCanvas().style.cursor = 'pointer';
            });

            this.map.on('mouseleave', 'sentiment_circle', () => {
                this.map.getCanvas().style.cursor = 'grab';
            });

            // this.map.on('mouseenter', 'ccc-erm', () => {
            //     this.map.getCanvas().style.cursor = 'pointer';
            // });
            //
            // this.map.on('mouseleave', 'ccc-erm', () => {
            //     this.map.getCanvas().style.cursor = 'grab';
            // });
            // this.map.on('click', 'ccc-erm', function(e) {
            //   if(that.map.getZoom() > 12){
            //     var coords = e.features[0].geometry.coordinates;
            //     var props = e.features[0].properties;
            //     var popup = new mapboxgl.Popup({
            //         anchor: "top"
            //       })
            //       .setLngLat(e.lngLat.wrap())//coords)
            //       .setHTML(that.generatePopup(e.features[0].properties, that.ermProperties))
            //       .addTo(e.target);
            //   }
            // });

            this.map.on('mouseenter', 'ccc-asp-shared', () => {
                this.map.getCanvas().style.cursor = 'pointer';
            });

            this.map.on('mouseleave', 'ccc-asp-shared', () => {
                this.map.getCanvas().style.cursor = 'grab';
            });
            this.map.on('click', 'ccc-asp-shared', function (e) {
                if (that.map.getZoom() > 12) {
                    var coords = e.features[0].geometry.coordinates;
                    var props = e.features[0].properties;
                    var popup = new mapboxgl.Popup({
                        anchor: "top"
                    })
                        .setLngLat(e.lngLat.wrap())//coords)
                        .setHTML(that.generatePopup(e.features[0].properties, that.language == 'en' ? that.aspProperties : that.aspPropertiesCY))
                        .addTo(e.target);
                }
            });
            this.map.on('mouseenter', 'ccc-asp', () => {
                this.map.getCanvas().style.cursor = 'pointer';
            });

            this.map.on('mouseleave', 'ccc-asp', () => {
                this.map.getCanvas().style.cursor = 'grab';
            });
            this.map.on('click', 'ccc-asp', function (e) {
                if (that.map.getZoom() > 12) {
                    var coords = e.features[0].geometry.coordinates;
                    var props = e.features[0].properties;
                    var popup = new mapboxgl.Popup({
                        anchor: "top"
                    })
                        .setLngLat(e.lngLat.wrap())//coords)
                        .setHTML(that.generatePopup(e.features[0].properties, that.language == 'en' ? that.aspProperties : that.aspPropertiesCY))
                        .addTo(e.target);
                }
            });
            this.map.on('mouseenter', 'ccc-asp-walk', () => {
                this.map.getCanvas().style.cursor = 'pointer';
            });

            this.map.on('mouseleave', 'ccc-asp-walk', () => {
                this.map.getCanvas().style.cursor = 'grab';
            });
            this.map.on('click', 'ccc-asp-walk', function (e) {
                if (that.map.getZoom() > 12) {
                    var coords = e.features[0].geometry.coordinates;
                    var props = e.features[0].properties;
                    var popup = new mapboxgl.Popup({
                        anchor: "top"
                    })
                        .setLngLat(e.lngLat.wrap())//coords)
                        .setHTML(that.generatePopup(e.features[0].properties, that.language == 'en' ? that.aspProperties : that.aspPropertiesCY))
                        .addTo(e.target);
                }
            });
        });

        this.map.on('sentiment_data', (e) => {
            //console.log("sentiment change");
        })

        this.map.on('data', (e) => {
            if (e.dataType === "style") {
                this.updateMarkers();
                return;
            }
            // console.log(e);
            // console.log(e.sourceId !== 'sentiment_data');
            // console.log(!e.isSourceLoaded);
            if (e.sourceId === 'sentiment_data') {
                //console.log("DATA UPDATE");
                //console.log(!e.isSourceLoaded);
                //console.log(e.sourceId === 'sentiment_data' || !e.isSourceLoaded);
            }
            // if (e.sourceId !== 'sentiment_data' || !e.isSourceLoaded) return;
            this.map.on('move', this.updateMarkers);
            this.map.on('moveend', this.updateMarkers);
            this.updateMarkers();
        });

        // Map click events for map markers
        this.map.on('click', 'sentiment_circle', e => {
            let lat = e.features[0].geometry.coordinates[0];
            let lng = e.features[0].geometry.coordinates[1];

            if (this.displayTooltips) this.displayTooltips = false;

            if(!this.wide)
            {
                this.$store.commit('toggleSidebar');
            }

            setTimeout(() => {
                this.map.resize();
            }, 50);

            e.target.flyTo({
                zoom: 18.9,
                pitch: 0,
                bearing: 0,
                center: [lat, lng],
                speed: 0.9
            });

            this.$router.push({name: "comment-view-page", params: {id: e.features[0].properties.id}}).catch(err => {
            });
        });

        var icons = document.getElementsByClassName('mapboxgl-ctrl-icon');
        for (var i = 0; i < icons.length; i++) {
            icons[i].setAttribute('tabindex', 3);
        }

        that.currentColourBlindMode = that.checkColourBlindMode();
        this.updateMarkers();
    },
    watch:{
        wide: function (val) {
            // console.log("wide");
            setTimeout(() => {
                this.map.resize();
                for (var id in this.markersOnScreen) {
                    if (!newMarkers[id]) {
                        this.markersOnScreen[id].remove();
                    }
                }
                updateMarkers();
            }, 50);
            // this.map.resize();
        },
    }
}
</script>
