<template>
  <header id="header">
	   <div class="container-fluid">
      <div id="logo-container">
	       <div id="logo">
	           <!-- <router-link to="/">Deetu</router-link> -->
             <a href="/" v-on:click.stop.prevent="flyToLandingPage" alt="Carmarthenshire County Council" title="Home">Deetu</a>
	       </div>
       </div>
	   <nav>
        <ul id="nav">
        <li v-if="this.user !== null">
          <a :href=getLogoutHref v-on:click.stop.prevent="logout">{{ $t('logout') }}</a>
        </li>
          <li v-if="this.user === null">
            <a :href=getLoginHref>{{ $t('login') }}</a>
          </li>
          <li v-if="this.user === null">
            <a :href=getRegHref>{{ $t('register') }}</a>
          </li>
          <li>
            <div id="toggle">
              <div class="lang" v-bind:class="{'selected': lang == 'cy'}" tabindex=0 @click="switchLangauge('cy')" @keyup.space="switchLangauge('cy')">CY</div>
              <div class="lang" v-bind:class="{'selected': lang == 'en'}" tabindex=0 @click="switchLangauge('en')" @keyup.space="switchLangauge('en')">EN</div>
            </div>
          </li>
          <li>
            <router-link v-if="checkConstraints(windowWidth)" to="/add-comment"><i class="material-icons">add_circle</i></router-link>
            <router-link :disabled="true" role="button" aria-pressed="false" v-else to="/add-comment" class="btn btn-brand btn-comment">{{ $t('addCommentButton') }}</router-link>
          </li>
			  </ul>
		  </nav>
    </div>
  </header>
</template>

<script>
    import i18n from '../../../lang/i18n';
    export default {
    	name: 'site-header',
        data() {
            return {
                lang: this.getLanguage(),
                languages:[
                  {flag: 'uk', language: 'en', title: 'English'},
                  {flag: 'es', language: 'es', title: 'Espanol'}
                ],
                user: window.user,
                windowWidth: window.innerWidth,
                phaseOneEndDate: null,
            }
        },
        methods: {
            logout(){
                axios.post('/' + this.lang + '/logout').then((response) => {
                    window.location.replace('/');
                }, (response) => {
                    this.error = response;
                });
            },
            flyToLandingPage() {
                this.$eventHub.$emit('fly-to-landing-page');
            },
            getLanguage() {
              ////console.log(document.cookie);
              var cookie = this.getLanguagCookie(document.cookie);
              return cookie == null ?  i18n.locale : cookie;
            },
            switchLangauge(locale) {
                document.cookie = 'locale='+ locale;
                this.lang = locale;
                i18n.locale = locale;
                this.$eventHub.$emit('language-switch');
            },
            onLoad(){
              //document.cookie = 'locale=en';
            },
            onResize(){
              this.windowWidth = window.innerWidth;
            },
            checkConstraints(width){
              if(width > 542 && (width > 1000 || width < 768)){
                return "";
              }
              return "btn-circle";
            },
            getLanguagCookie(doc){
              var x = doc.split(';');
              for(var i = 0; i < x.length; i++){
                var key = x[i].split('=')[0].trim();
                if(key == "locale"){
                  return x[i].split('=')[1].trim();
                }
              }
              return null;
            }
        },
        mounted() {
          this.phaseOneEndDate = Date.parse("2021-06-20"),
          this.onLoad(),
          this.$nextTick(()=>{
            window.addEventListener('resize', this.onResize)
          })
        },
        computed: {
          getRegHref(){
            return "/" + this.lang + "/register";
          },
          getLoginHref(){
            return "/" + this.lang + "/login";
          },
          getLogoutHref(){
            return "/" + this.lang + "/logout";
          }
        }
    }
</script>
