<template>
    <div>
        <site-header></site-header>
        <aside id="sidebar" :class="{ narrow : !hide }" @click="openSidebarComponent">
            <router-view></router-view>
        </aside>
        <the-map v-if="showMap" key="pageMapMap"></the-map>

        <div class="card card--popup" v-if="showRegisteredPopup">
            <div class="card__content">
              <h2>{{ $t('pageMapRegisterPopupHeader') }}</h2>
              <h2 v-show="showAddedComment">{{ $t('pageMapRegisterPopupAddedComment') }}</h2>
              <h2 v-show="!showAddedComment">{{ $t('pageMapRegisterPopupAddComment') }}</h2>
            </div>
             <div class="cookie-confirmation__close">
                <div class="close" v-on:click.stop.prevent="toggleShowRegisteredPopup">
                    <svg viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g stroke-width="1" fill-rule="evenodd">
                            <path d="M1.84314575,2.70710678 C1.45262146,2.31658249 1.45262146,1.68341751 1.84314575,1.29289322 C2.23367004,0.902368927 2.86683502,0.902368927 3.25735931,1.29289322 L9.5,7.53553391 L15.7426407,1.29289322 C16.133165,0.902368927 16.76633,0.902368927 17.1568542,1.29289322 C17.5473785,1.68341751 17.5473785,2.31658249 17.1568542,2.70710678 L10.2071068,9.65685425 C9.84447708,10.0194839 9.27263169,10.0453861 8.88011403,9.73456061 L8.79289322,9.65685425 L1.84314575,2.70710678 Z" id="Path"></path>
                            <path d="M1.84314575,9.70710678 C1.45262146,9.31658249 1.45262146,8.68341751 1.84314575,8.29289322 C2.23367004,7.90236893 2.86683502,7.90236893 3.25735931,8.29289322 L9.5,14.5355339 L15.7426407,8.29289322 C16.133165,7.90236893 16.76633,7.90236893 17.1568542,8.29289322 C17.5473785,8.68341751 17.5473785,9.31658249 17.1568542,9.70710678 L10.2071068,16.6568542 C9.84447708,17.0194839 9.27263169,17.0453861 8.88011403,16.7345606 L8.79289322,16.6568542 L1.84314575,9.70710678 Z" id="Path-Copy" transform="translate(9.500000, 12.474874) scale(1, -1) translate(-9.500000, -12.474874) "></path>
                        </g>
                    </svg>
                </div>
            </div>
        </div>

        <div>
          <modal  v-if="showModal" @close="closeModal"/>
        </div>

        <div class="cookie-confirmation" v-if="cookie_confirmation === 0">
            <div class="cookie-confirmation__content">
              <p>{{ $t('pageMapCookiePopupHeader') }}<a target="_blank" href="/privacy-policy">{{ $t('pageMapCookiePopupLink') }}</a></p>
            </div>
            <div class="cookie-confirmation__footer">
              <a href="" class="btn btn-cookies" v-on:click.stop.prevent="confirmCookies">{{ $t('pageMapCookiePopupButton') }}</a>
            </div>
            <div class="cookie-confirmation__close">
                <div class="close" v-on:click.stop.prevent="confirmCookies">
                    <svg viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g stroke-width="1" fill-rule="evenodd">
                            <path d="M1.84314575,2.70710678 C1.45262146,2.31658249 1.45262146,1.68341751 1.84314575,1.29289322 C2.23367004,0.902368927 2.86683502,0.902368927 3.25735931,1.29289322 L9.5,7.53553391 L15.7426407,1.29289322 C16.133165,0.902368927 16.76633,0.902368927 17.1568542,1.29289322 C17.5473785,1.68341751 17.5473785,2.31658249 17.1568542,2.70710678 L10.2071068,9.65685425 C9.84447708,10.0194839 9.27263169,10.0453861 8.88011403,9.73456061 L8.79289322,9.65685425 L1.84314575,2.70710678 Z" id="Path"></path>
                            <path d="M1.84314575,9.70710678 C1.45262146,9.31658249 1.45262146,8.68341751 1.84314575,8.29289322 C2.23367004,7.90236893 2.86683502,7.90236893 3.25735931,8.29289322 L9.5,14.5355339 L15.7426407,8.29289322 C16.133165,7.90236893 16.76633,7.90236893 17.1568542,8.29289322 C17.5473785,8.68341751 17.5473785,9.31658249 17.1568542,9.70710678 L10.2071068,16.6568542 C9.84447708,17.0194839 9.27263169,17.0453861 8.88011403,16.7345606 L8.79289322,16.6568542 L1.84314575,9.70710678 Z" id="Path-Copy" transform="translate(9.500000, 12.474874) scale(1, -1) translate(-9.500000, -12.474874) "></path>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SiteHeader from '../components/partials/Header';
    import TheMap from '../components/TheMap';
    import {mapState} from 'vuex';

    export default {
        name: 'PageMap',
        components: {
            SiteHeader,
            TheMap
        },
        data() {
            return {
                cookie_confirmation: 1,
                showMap: !(this.mode === 'comment' && window.innerWidth <= 767),
                showRegisteredPopup: false,
                showAddedComemnt: false,
                showModal: false,
                showSidebar: true,
                canHide: false,
            }
        },
        computed:{
            ...mapState({
                mode: state => state.mode,
                hide: state => state.sidebarOpen,
            }),
        },
        watch: {
            mode(newValue, oldValue) {
                this.showMapInComponent();
            },
            hide(){
                if(!this.canHide)
                {
                    setTimeout(() => {
                        this.canHide = true;
                    }, 50);
                }
                else{
                    this.canHide = false;
                }
            }
        },
        mounted(){
            // check route if registered
            this.showRegisteredPopup = this.$route.query.registered;
            this.showAddedComment = this.$route.query.comment_added;

            // check the cookie
            if (document.cookie.split(';').filter( item => item.trim().startsWith('cookies_confirmation=')).length == 0) {
                this.cookie_confirmation = 0;
            }

            // Coppy-pasted for language get
            if (document.cookie.split(';').filter( item => item.trim().startsWith('locale=')).length == 0) {
                this.showModal = true;
            }
        },
        methods: {
            toggleShowRegisteredPopup() {
                this.showRegisteredPopup = !this.showRegisteredPopup;
            },
            confirmCookies() {
                var expiry = new Date();
                expiry.setTime(expiry.getTime()+(20*365*24*60*60*1000));
                document.cookie = "cookies_confirmation=1; expires=" + expiry.toGMTString();
                this.cookie_confirmation = 1;
            },
            showMapInComponent() {
                if (this.mode === 'comment' && window.innerWidth <= 767) {
                    return this.showMap = false;
                }
                return this.showMap = true;
            },
            closeModal(){
              this.showModal = false;
            },
            openSidebarComponent(){
                if(this.canHide)
                {
                    // console.log('open side bar');
                    this.$store.commit('toggleSidebar');
                }
            }
        }
    }
</script>
