<template>
    <div class="container-fluid landing-page">
      
          <div class="close" @click="hideSidebar" v-if="hide">
            Hide Menu
            <!-- <svg viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M1.84314575,2.70710678 C1.45262146,2.31658249 1.45262146,1.68341751 1.84314575,1.29289322 C2.23367004,0.902368927 2.86683502,0.902368927 3.25735931,1.29289322 L9.5,7.53553391 L15.7426407,1.29289322 C16.133165,0.902368927 16.76633,0.902368927 17.1568542,1.29289322 C17.5473785,1.68341751 17.5473785,2.31658249 17.1568542,2.70710678 L10.2071068,9.65685425 C9.84447708,10.0194839 9.27263169,10.0453861 8.88011403,9.73456061 L8.79289322,9.65685425 L1.84314575,2.70710678 Z" id="Path" fill="#8A8D8E"></path>
                    <path d="M1.84314575,9.70710678 C1.45262146,9.31658249 1.45262146,8.68341751 1.84314575,8.29289322 C2.23367004,7.90236893 2.86683502,7.90236893 3.25735931,8.29289322 L9.5,14.5355339 L15.7426407,8.29289322 C16.133165,7.90236893 16.76633,7.90236893 17.1568542,8.29289322 C17.5473785,8.68341751 17.5473785,9.31658249 17.1568542,9.70710678 L10.2071068,16.6568542 C9.84447708,17.0194839 9.27263169,17.0453861 8.88011403,16.7345606 L8.79289322,16.6568542 L1.84314575,9.70710678 Z" id="Path-Copy" fill="#8A8D8E" transform="translate(9.500000, 12.474874) scale(1, -1) translate(-9.500000, -12.474874) "></path>
                </g>
            </svg> -->
          </div>

        <h1>{{ $t('welcomeTitle') }}</h1>
        <p>{{ $t('pageLandingPrefaceOne') }}</p>
        <p>{{ $t('pageLandingPrefaceTwo') }}</p>
        <p>{{ $t('pageLandingPrefaceThree') }}</p>
        <p>{{ $t('pageLandingPrefaceFour') }}</p>
        <ul>
          <li>{{ $t('pageLandingPrefaceBulletOne') }}</li>
          <li>{{ $t('pageLandingPrefaceBulletTwo') }}</li>
        </ul>
        <p><strong>{{ $t('pageLandingPrefaceExtra') }}</strong></p>
        <!--<p><strong>Zoom into an area of interest to see the Existing Route Network <img src="/images/erm-icon.svg"/> and Future Routes <img src="/images/inm-icon.svg"/> in more detail. You can also clear these routes from the map by unchecking them in the map key.</strong></p>-->

        <div class="collapsable collapsable--column">
            <div class="collapsable__content">
              <p>{{ $t('pageLandingCollapseOne') }}</p>
              <p>{{ $t('pageLandingCollapseTwo') }}</p>
              <p>{{ $t('pageLandingPrefaceThree') }}</p>
              <p>{{ $t('pageLandingCollapseFour')}} <a href="http://www.legislation.gov.uk/anaw/2013/7/contents/enacted" target="_blank">http://www.legislation.gov.uk/anaw/2013/7/contents/enacted</a></p>
            </div>
              <div id="logo-wg" alt="Funded by the Welsh Government" title="Funded by the Welsh Government" class="landing"/>
            <div id="landing-page__nav">
                <!-- <a href="#" class="btn" id="landing" v-on:click.stop.prevent="toggleLearnMore">{{ $t('pageLandingLearnMore') }}</a> -->
                <router-link to="/add-comment" id="landing" class="btn btn-brand btn-comment">{{ $t('addCommentButton') }}</router-link>
            </div>

        </div>

        <div v-if="!hide" class="chevron">
          Show Menu
        </div>

        <div class="landing-footer land" v-if="hide">
          {{ $t('mapBuiltOn') }} <a href="https://deetu.com" target="_blank">Deetu Engage</a> -
          <a href="mailto:engage@deetu.com?Subject=Carmarthenshire Active Travel Feedback" target="_top">{{ $t('mapImprove') }}</a>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
    data() {
      return {
        windowWidth: window.innerWidth,
        phaseOneEnd: false,
      }
    },
    computed:{
        ...mapState({
            hide: state => state.sidebarOpen,
        }),
    },
    mounted() {
      this.$nextTick(()=>{
        window.addEventListener('resize', this.onResize)
      }),
      this.phaseOneEnd = Date.now() > Date.parse("2021-02-01") ? true : false;
    },
    methods: {
      toggleLearnMore(event) {
        var content = event.target.parentElement.previousElementSibling.previousElementSibling;
        if (content.style.maxHeight) {
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = "100%";
        }
      },
      onResize(){
        this.windowWidth = window.innerWidth;
      },
      checkConstraints(width){
        if(width > 542 && (width > 825 || width < 768)){
          return "";
        }
          return "btn-circle";
        },
      hideSidebar(){
        // console.log("hide sidebar");
        this.$store.commit('toggleSidebar');
        }
      },
    }
</script>
