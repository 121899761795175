<template>
    <div>
        <router-link to="/" class="close">
            <svg viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M1.84314575,2.70710678 C1.45262146,2.31658249 1.45262146,1.68341751 1.84314575,1.29289322 C2.23367004,0.902368927 2.86683502,0.902368927 3.25735931,1.29289322 L9.5,7.53553391 L15.7426407,1.29289322 C16.133165,0.902368927 16.76633,0.902368927 17.1568542,1.29289322 C17.5473785,1.68341751 17.5473785,2.31658249 17.1568542,2.70710678 L10.2071068,9.65685425 C9.84447708,10.0194839 9.27263169,10.0453861 8.88011403,9.73456061 L8.79289322,9.65685425 L1.84314575,2.70710678 Z" id="Path" fill="#8A8D8E"></path>
                    <path d="M1.84314575,9.70710678 C1.45262146,9.31658249 1.45262146,8.68341751 1.84314575,8.29289322 C2.23367004,7.90236893 2.86683502,7.90236893 3.25735931,8.29289322 L9.5,14.5355339 L15.7426407,8.29289322 C16.133165,7.90236893 16.76633,7.90236893 17.1568542,8.29289322 C17.5473785,8.68341751 17.5473785,9.31658249 17.1568542,9.70710678 L10.2071068,16.6568542 C9.84447708,17.0194839 9.27263169,17.0453861 8.88011403,16.7345606 L8.79289322,16.6568542 L1.84314575,9.70710678 Z" id="Path-Copy" fill="#8A8D8E" transform="translate(9.500000, 12.474874) scale(1, -1) translate(-9.500000, -12.474874) "></path>
                </g>
            </svg>
        </router-link>

        <div class="container-fluid form-intro">
            <h1>{{ $t('addCommentTitle') }}</h1>
            <p>{{ $t('addCommentPreface') }}</p>
            <p><strong>{{ $t('addCommentAnonymous') }}</strong></p>
            <p><strong>*{{ $t('addCommentResponse') }}</strong></p>
            <p><strong>**{{ $t('addCommentCovid') }}</strong></p>
        </div>

        <div class="form-item">
            <div class="num">1</div>
            <div>
                <label>{{ $t('addCommentFindLocation') }}</label>
                <p>{{ $t('addCommentFindLocationDesc') }}</p>
                <div id='geocoder' tabindex=1></div>
                <p class="error-message">{{ error.location[0] }}</p>
            </div>
        </div>

        <the-map v-if="showMap" key="pageAddCommentMap"></the-map>

        <input type="hidden" name="lat" v-model="comment.location.lat">
        <input type="hidden" name="lng" v-model="comment.location.lng">

        <div class="form-item shaded">
            <div class="num">3</div>
            <div>
                <label>{{ $t('addCommentCommentTitle') }}*</label>
                <p>{{ $t('addCommentCommentTitleDesc') }}</p>
                <input tabindex=2 type="text" name="title" maxlength="50" v-model="comment.title">
                <p class="error-message">{{ error.title[0] }}</p>
            </div>
        </div>

        <div class="form-item">
            <div class="num">4</div>
            <div>
                <label>{{ $t('addCommentCategories') }}*</label>
                <p>{{ $t('addCommentCategoriesDesc') }}</p>
                <div class="button-group-pills text-center" data-toggle="buttons">
                    <label v-for="category in categories" :class="{ 'selected' : (comment.categories.indexOf(category.id) !== -1) }">
                        <input tabindex=2 type="checkbox" v-model="comment.categories" :value="category.id">
                        <div>{{ comment.language == 'en' ? category.title : category.title_welsh }}</div>
                    </label>
                    <p class="error-message">{{ error.categories[0] }}</p>
                </div>
            </div>
        </div>

        <div class="form-item shaded">
            <div class="num">5</div>
            <div>
                <label>{{ $t('addCommentSentiment') }}*</label>
                <p>{{ $t('addCommentSentimentDesc') }}</p>
                <div id="slider" @click="slideToPip"></div>
            </div>
        </div>

        <div class="form-item">
            <div class="num">6</div>
            <div>
                <label>{{ $t('addCommentActivities') }}*</label>
                <p>{{ $t('addCommentActivitiesDesc') }}</p>
                <div class="button-group-pills text-center" data-toggle="buttons">
                    <label v-for="activity in activities" :class="{ 'selected' : (comment.activities.indexOf(activity.id) !== -1) }">
                        <input tabindex=2 type="checkbox" v-model="comment.activities" :value="activity.id">
                        <div>{{ comment.language == 'en' ? activity.title : activity.title_welsh }}</div>
                    </label>
                    <p class="error-message">{{ error.activities[0] }}</p>
                </div>
            </div>
        </div>

        <div class="form-item shaded">
            <div class="num">7</div>
            <div>
                <label>{{ $t('addImageAction') }}</label>
                <p>{{ $t('addImageActionDesc') }}</p>

                <input v-for="category in comment.image_ids" type="hidden" v-model="category.id" />
                <vue-dropzone ref="myVueDropzone" id="image-dropzone" :options="dropzoneOptions"></vue-dropzone>
                <!-- <p class="error-message">{{ error.action_id[0] }}</p>-->
            </div>
        </div>

        <div class="form-item ">
            <div class="num">8</div>
            <div>
                <label>{{ $t('addCommentAction') }}*</label>
                <p>{{ $t('addCommentActionDesc') }}</p>
                <select tabindex=2 v-model="comment.action_id">
                    <option v-for="action in actions" :value="action.id">{{ comment.language == 'en' ? action.title : action.title_welsh}}</option>
                </select>
                <p class="error-message">{{ error.action_id[0] }}</p>
            </div>
        </div>

        <div class="form-item shaded">
            <div class="num">9</div>
            <div>
                <label>{{ $t('addCommentComment') }}</label>
                <p>{{ $t('addCommentCommentDesc') }}</p>
                <textarea tabindex=2 name="comment" maxlength="600" v-model="comment.comment"></textarea>

                <div class="align-center pad-two">
                    <button :disabled="submitted" tabindex=3 class="btn btn-green" v-on:click.stop.prevent="saveComment">{{ $t('addCommentSubmit') }}</button>
                    <p v-if="submitted">{{ $t('addCommentLoading') }}</p>
                    <ul id="errors">
                      <li v-for="er in error" v-if="er[0] != null">{{er[0]}}</li>
                    </ul>
                </div>
            </div>
        </div>

        <input type="hidden" name="language" v-model="comment.language" />
    </div>
</template>

<script>
    //var noUiSlider = require('nouislider/distribute/nouislider.min.js');
    import noUiSlider from 'nouislider';
    import {mapState} from 'vuex';
    import TheMap from '../components/TheMap';
    import i18n from '../../lang/i18n';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
    mapboxgl.accessToken = 'pk.eyJ1Ijoiam9zaC1kZWV0dSIsImEiOiJjazVwaWN0cDEwYTJ6M2xxdm01NTV1OXRhIn0.3zsfP502LOrnO4XYM4kS2w';
    export default {
        components: {
            TheMap,
            vueDropzone: vue2Dropzone
        },
        data() {
            return {
                showMap: (this.mode === 'comment' && window.innerWidth <= 767),
                user: window.user,
                comment: {
                    title: "",
                    action_id: "",
                    sentiment: 2,
                    comment: "",
                    categories: [],
                    activities: [],
                    location: {
                        lat: null,
                        lng: null
                    },
                    language: "",
                    phase: 0,
                    image_ids: []
                },
                slider: null,
                sliderLabelsEN: [
                    'Very Negative',
                    'Quite Negative',
                    'Neutral',
                    'Quite Positive',
                    'Very Positive'
                ],
                sliderLabelsCY: [
                    'Negyddol Iawn',
                    'Eithaf Negyddol',
                    'Niwtral',
                    'Eithaf Cadarnhaol',
                    'Cadarnhaol Iawn'
                ],
                actions: window.actions,
                categories: window.categories,
                activities: window.activities,
                error: {
                    location: [],
                    title: [],
                    categories: [],
                    activities: [],
                    action_id: [],
                },
                searchLang: { en: 'Search Location', cy: 'Chwilio am leoliad' },
                submitted: false,
                phaseTwoDate: null,
                today: null,
                dropzoneOptions: {
                    url: '/api/image-upload',
                    thumbnailWidth: 100,
                    thumbnailHeight: 100,
                    maxFilesize: 10,
                    uploadMultiple: false,
                    maxFiles: 2,
                    addRemoveLinks: true,
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').content ?? ""
                    },
                    success: (file, response) => {
                        if(typeof response.file_id !== "undefined"){
                            this.comment.image_ids = [...this.comment.image_ids, {id: response.file_id}];
                        }

                        file.file_id = response.file_id;

                        return file;
                    },
                    removedfile: (file) => {
                        if (file.previewElement != null && file.previewElement.parentNode != null) {
                            file.previewElement.parentNode.removeChild(file.previewElement);
                        }

                        axios.delete(`/api/image/${file.file_id}`, {})
                            .then(function (response) {
                                this.$refs.myVueDropzone.removeFile(file);
                            })
                    },
                    dictDefaultMessage: i18n.messages[i18n.locale].dropzoneDefaultMessage,
                    dictFallbackMessage: i18n.messages[i18n.locale].dropzoneFallbackMessage,
                    dictFallbackText: i18n.messages[i18n.locale].dropzoneFallbackText,
                    dictFileTooBig: i18n.messages[i18n.locale].dropzoneFileTooBig,
                    dictInvalidFileType: i18n.messages[i18n.locale].dropzoneInvalidFileType,
                    dictResponseError: i18n.messages[i18n.locale].dropzoneResponseError,
                    dictCancelUpload: i18n.messages[i18n.locale].dropzoneCancelUpload,
                    dictUploadCanceled: i18n.messages[i18n.locale].dropzoneUploadCanceled,
                    dictCancelUploadConfirmation: i18n.messages[i18n.locale].dropzoneCancelUploadConfirmation,
                    dictRemoveFile: i18n.messages[i18n.locale].dropzoneRemoveFile,
                    dictMaxFilesExceeded: i18n.messages[i18n.locale].dropzoneMaxFilesExceeded,
                }
            }
        },
        computed:{
            ...mapState({
                mode: state => state.mode,
                mapLatLng: state => state.mapLatLng,
                sidebarOpen: state => state.sidebarOpen,
            }),
        },
        watch:{
            mode(newValue, oldValue) {
                this.showMapInComponent();
            },
            mapLatLng(newValue, oldValue) {
                this.comment.location.lat = newValue.lat;
                this.comment.location.lng = newValue.lng;
            },
        },
        destroyed(){
            this.$store.commit('viewMode');
        },
        mounted() {
            this.comment.language = i18n.locale;

            this.slider = document.getElementById('slider');

            this.phaseTwoDate = Date.parse("2021-08-09");
            this.today = Date.now();

            if(this.today > this.phaseTwoDate) {
              // Adds phase 2 id to categories
              this.comment.phase = 2;
            }
            else{
              this.comment.phase = 1;
            }

            noUiSlider.create(slider, {
                start: [this.comment.sentiment],
                step: 1,
                connect: true,
                range: {
                    'min': 0,
                    'max': 4
                },
                pips:{
                    mode: 'values',
                    values: [0, 1, 2, 3, 4],
                    density: 100,
                    format: {
                      to: value => this.comment.language == 'en' ? this.sliderLabelsEN[value] || '' : this.sliderLabelsCY[value] || '' ,
                      from: label => this.comment.language == 'en' ? this.sliderLabelsEN.indexOf(label) : this.sliderLabelsCY.indexOf(label) ,
                  }
                }
            });

            this.slider.noUiSlider.on('change', (value) => {
                this.comment.sentiment = parseInt(value[0]);
            });

            this.comment.language = i18n.locale;

            this.$store.commit('commentMode');

            setTimeout(() => this.updateSearchBar(), 1000);

            document.getElementsByClassName('noUi-touch-area')[0].setAttribute('tabindex', '2');

            this.$eventHub.$on("language-switch", msg => {
                this.dropzoneOptions = {
                    ...this.dropzoneOptions,
                    dictDefaultMessage: i18n.messages[i18n.locale].dropzoneDefaultMessage,
                    dictFallbackMessage: i18n.messages[i18n.locale].dropzoneFallbackMessage,
                    dictFallbackText: i18n.messages[i18n.locale].dropzoneFallbackText,
                    dictFileTooBig: i18n.messages[i18n.locale].dropzoneFileTooBig,
                    dictInvalidFileType: i18n.messages[i18n.locale].dropzoneInvalidFileType,
                    dictResponseError: i18n.messages[i18n.locale].dropzoneResponseError,
                    dictCancelUpload: i18n.messages[i18n.locale].dropzoneCancelUpload,
                    dictUploadCanceled: i18n.messages[i18n.locale].dropzoneUploadCanceled,
                    dictCancelUploadConfirmation: i18n.messages[i18n.locale].dropzoneCancelUploadConfirmation,
                    dictRemoveFile: i18n.messages[i18n.locale].dropzoneRemoveFile,
                    dictMaxFilesExceeded: i18n.messages[i18n.locale].dropzoneMaxFilesExceeded,
                }
            });
        },
        methods: {
            showMapInComponent() {
                if (this.mode === 'comment' && window.innerWidth <= 767) {
                    return this.showMap = true;
                }
                return this.showMap = false;
            },
            saveComment(){
                this.submitted = true;
                axios.post('/api/comments', this.comment).then((response) => {
                    // this.post = response.data;
                    // this.$router.push({ name: 'edit-post', params: { group: this.$route.params.group, post: this.post.slug }});
                    if(this.user){
                        window.location.replace('/comments/' + response.data.id);
                    }else{
                        window.location.replace('/' + i18n.locale + '/register');
                    }
                }, (xhr) => {
                    let errors = xhr.response.data.errors ?? {};

                    if(!Object.values(error).length) {
                        this.error.unknown = "An error occurred";
                    } else {
                        this.error.location = errors && errors['location.lat'] !== undefined ? errors['location.lat'] : '';
                        this.error.title = errors && errors.title !== undefined ? errors.title : '';
                        this.error.categories = errors && errors.categories !== undefined ? errors.categories : '';
                        this.error.activities = errors && errors.activities !== undefined ? errors.activities : '';
                        this.error.action_id = errors && errors.action_id !== undefined ? errors.action_id : '';
                    }

                    this.submitted = false;
                });
            },
            slideToPip(e) {
                if(e.target.dataset.value) {
                    var value = parseInt(e.target.dataset.value)
                    this.slider.noUiSlider.set(value);
                    this.comment.sentiment = value;
                }
            },
            updateSearchBar(){
              var searchBar = document.getElementsByClassName('mapboxgl-ctrl-geocoder--input');
              for(var i = 0; i < searchBar.length; i++){
                searchBar[i].placeholder = i18n.locale == 'en' ? this.searchLang.en : this.searchLang.cy;
              }
            }
        },
    }
</script>

<style lang="scss">
    #slider{
        height: 1rem;
        border-color: transparent;
        width: 85%;
        margin: 0 auto 4rem auto;

        .noUi-connects{
            background-image: linear-gradient(90deg, #EE3E34 0%, #EC671C 19.73%, #F1C544 53.35%, #C3CB04 81.22%, #81BC00 100%);
        }

        .noUi-value{
            font-size: 1.1rem;
            font-weight: bold;
            color: #27348B;

            @media only screen and (max-width: 767px) {
                font-size: 1rem;
            }

            &[data-value="1"]{
                @media only screen and (max-width: 1060px) and (min-width: 767px){
                  padding-top: 1rem;
                }
                @media only screen and (max-width: 360px){
                  padding-top: 1rem;
                }
            }

            &[data-value="3"]{
                @media only screen and (max-width: 1060px) and (min-width: 767px){
                  padding-top: 1rem;
                }
                @media only screen and (max-width: 360px){
                  padding-top: 1rem;
                }
            }
        }


        .noUi-marker{
            bottom: 0;
            display: none;
        }

        .noUi-handle{
            background: transparent url('/images/handle.png') 0 0;
            background-size: contain;
            border: none;
            box-shadow: none;
            width: 22px;
            height: 25px;
            right: -11px;

            &:before, &:after{
                content: none;
            }
        }
    }
</style>
